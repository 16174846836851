export interface OutputColumnTemplate {
  name: string;
  required: boolean;
  key: string;
  type: 'string' | 'number' | 'boolean';
  maxLength?: number;
  unique?: boolean;
  uniqueWithKeys?: string[];
  canHaveCustomDefault?: boolean;
  default?: string;
  autoAssigned?: boolean;
}

export const CHUNK_SIZE = 1000;

export const codeColumnKey = 'B';
export const supplierProductCodeColumnKey = 'U';
export const supplierCodeColumnKey = 'T';

export const outputColumns: OutputColumnTemplate[] = [
  // Algemeen
  {
    name: 'Artikelgroep: Code',
    required: false,
    key: 'A',
    type: 'string',
    maxLength: 30,
  }, {
    name: 'Code',
    required: true,
    key: 'B',
    type: 'string',
    maxLength: 30,
    canHaveCustomDefault: false,
    unique: true,
    autoAssigned: true,
  }, {
    name: 'Zoekcode',
    required: false,
    key: 'C',
    type: 'string',
    maxLength: 13,
  }, {
    name: 'Omschrijving (Dutch)',
    required: true,
    key: 'D',
    type: 'string',
    maxLength: 60,
  }, {
    name: 'Extra tekst (Dutch)',
    required: false,
    key: 'E',
    type: 'string',
    maxLength: 255,
  }, {
    name: 'Barcode',
    required: false,
    key: 'F',
    type: 'number',
    maxLength: 20,
    canHaveCustomDefault: false,
    unique: false,
  },

  // Financieel
  {
    name: 'Kostprijs',
    required: false,
    key: 'G',
    type: 'number',
  }, {
    name: 'Verkoopprijs',
    required: false,
    key: 'H',
    type: 'number',
  }, {
    name: 'Eenheid: Code',
    required: true,
    key: 'I',
    type: 'string',
    maxLength: 8,
    default: 'STUKS',
  }, {
    name: 'BTW-code: Verkoop',
    required: false,
    key: 'J',
    type: 'number',
    maxLength: 3,
  },

  // Hoofdleverancier
  {
    name: 'Valuta: Inkoop',
    required: false,
    key: 'K',
    type: 'string',
    maxLength: 3,
  }, {
    name: 'Drop shipment',
    required: false,
    key: 'L',
    type: 'boolean',
    default: 'Nee',
  }, {
    name: 'Besteldoorlooptijd',
    required: true,
    key: 'M',
    type: 'number',
    default: '1',
    maxLength: 6,
  }, {
    name: 'Inkoopprijs',
    required: true,
    key: 'N',
    type: 'number',
  }, {
    name: 'Inkoopeenheid',
    required: true,
    key: 'O',
    type: 'string',
    maxLength: 8,
    default: 'STUKS',
  }, {
    name: 'Inkoopeenheidsfactor',
    required: true,
    key: 'Q',
    type: 'number',
    default: '1',
    maxLength: 10,
  }, {
    name: 'BTW-code inkoop',
    required: true,
    key: 'R',
    type: 'number',
    default: '4',
    maxLength: 3,
  }, {
    name: 'Opmerkingen: Inkoop',
    required: false,
    key: 'S',
    type: 'string',
  }, {
    name: 'Leveranciercode',
    required: true,
    key: 'T',
    type: 'number',
    maxLength: 18,
    canHaveCustomDefault: false,
    autoAssigned: true,
  }, {
    name: 'Artikelcode leverancier',
    required: true,
    key: 'U',
    type: 'string',
    maxLength: 60,
    unique: true,
    uniqueWithKeys: ['F'],
    canHaveCustomDefault: false,
  }, {
    name: 'Deelbaar',
    required: false,
    key: 'V',
    type: 'boolean',
    default: 'Ja',
  }, {
    name: 'Ordergestuurd',
    required: false,
    key: 'W',
    type: 'boolean',
    default: 'Ja',
  }, {
    name: 'Inkoop',
    required: false,
    key: 'X',
    type: 'boolean',
    default: 'Ja',
  }, {
    name: 'Verkoop',
    required: false,
    key: 'Y',
    type: 'boolean',
    default: 'Ja',
  }, {
    name: 'Voorraad',
    required: false,
    key: 'Z',
    type: 'boolean',
    default: '',
  },
];

/**
 * Return a list of all keys this column is unique with
 * @param key
 */
export const getMatchingKeys = (key: string): string[] => {
  const supplierItemCodeColumn = outputColumns.find((c) => c.key === key)!;
  if (!supplierItemCodeColumn) return [key];
  return [
    supplierProductCodeColumnKey,
    ...supplierItemCodeColumn.uniqueWithKeys || [],
  ];
};

export interface OutputRow {
  [key: string]: string | number | undefined;
}
