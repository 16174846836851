import { OutputRow, outputColumns } from '../entities/output';

const separator = ',';

export default function getCsv(rows: OutputRow[]): string {
  const csvContent = `${
    outputColumns.map((col) => col.name).join(separator)}\r\n${
    rows.map((row) => outputColumns.map((col) => {
      if (row[col.key] == null) {
        return '';
      }
      return `"${row[col.key]?.toString().replaceAll('"', '""')}"`;
    }).join(separator)).join('\r\n')}`;

  const encodedUri = encodeURI(csvContent);
  return `data:text/csv;charset=utf-8,%EF%BB%BF ${encodedUri}`;
}
