import React from 'react';
import './App.css';
import firebase from 'firebase/compat/app';
import LoginView from './views/LoginView';
import BaseLayout from './components/BaseLayout';
import ConverterViewWrapper from './views/ConverterViewWrapper';
import FirebaseContext from './firebaseContext';
import UserInfo = firebase.UserInfo;
import LoadingView from './views/LoadingView';

function App(): JSX.Element {
  const { auth } = React.useContext(FirebaseContext);

  const [currentUser, setCurrentUser] = React.useState<UserInfo | null>(auth.currentUser);
  const [loading, setLoading] = React.useState(true);

  auth.onAuthStateChanged((user) => {
    setCurrentUser(user);
    setLoading(false);
  });

  const logout = (): void => {
    auth.signOut()
      .then(() => setCurrentUser(null))
      .catch((error) => console.error('Could not sign out', error));
  };

  let content;
  if (loading) {
    content = <LoadingView />;
  } else if (currentUser == null) {
    content = <LoginView />;
  } else {
    content = <ConverterViewWrapper />;
  }

  return (
    <BaseLayout currentUser={currentUser} logout={logout}>
      {content}
    </BaseLayout>
  );
}

export default App;
