// eslint-disable-next-line import/prefer-default-export
export function splitIntoChunks<T>(array: T[], chunks: number): T[][] {
  const chunkSize = Math.ceil(array.length / chunks);
  const result: T[][] = [];
  for (let i = 0, o = 0; i < chunks; i += 1, o += chunkSize) {
    result.push(array.slice(o, o + chunkSize));
  }
  return result;
}

/**
 * Return the powerset of a given array (set)
 * @param array
 */
export function powerSet<T>(array: T[]): T[][] {
  return array.reduce(
    (subsets: T[][], value: T) => subsets.concat(
      subsets.map((set) => [value, ...set]),
    ),
    [[]],
  );
}
