import React from 'react';
import {
  Alert, Box, Typography,
} from '@mui/material';
import StepCard from '../StepCard';
import { InputRow, InputColumnHeader } from '../../entities/input';
import PreviewTable from '../PreviewTable';

interface Props {
  headers: InputColumnHeader[] | null;
  input: InputRow[] | null;
}

const InputFilePreview = React.memo(({ headers, input }: Props): JSX.Element => {
  const muiTable = (): JSX.Element | null => {
    if (!input || !headers) return null;

    const headers2 = headers.map((h) => h.name);
    const rows = input.slice(0, 10)
      .map((i) => headers
        .map((h) => i[h.key]));

    return (
      <>
        Totaal aantal rijen:
        {' '}
        {input.length}
        <PreviewTable headers={headers2} rows={rows} />
      </>
    );
  };

  return (
    <StepCard step={4} title="Controlleer inputbestand">
      <Typography variant="body1">
        Hieronder staat een tabel met de eerste paar rijen van het gelezen inputbestand.
        Controleer of deze kloppen.
        Zo niet, pas het bestand of de bovengenoemde opties aan en lees het bestand opnieuw.
      </Typography>

      {input && input.length === 0 && (
        <Alert variant="filled" severity="error" sx={{ marginTop: '1rem' }}>
          Er zijn geen producten gelezen. Klopt het inputbestand?
        </Alert>
      )}

      <Box sx={{ marginTop: '1rem' }}>
        {muiTable()}
      </Box>
    </StepCard>
  );
});

export default InputFilePreview;
