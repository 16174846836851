import React from 'react';
import { Container } from '@mui/material';
import firebase from 'firebase/compat/app';
import NavBar from '../navigation/NavBar';
import UserInfo = firebase.UserInfo;

interface Props {
  children: React.ReactNode | React.ReactNode[]
  currentUser: UserInfo | null
  logout: () => void
}

function BaseLayout({ children, currentUser, logout }: Props): JSX.Element {
  return (
    <>
      <NavBar currentUser={currentUser} logout={logout} />
      <Container maxWidth="xl">
        {children}
      </Container>
    </>
  );
}

export default BaseLayout;
