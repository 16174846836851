import React from 'react';
import { Grid } from '@mui/material';
import * as XLSX from 'xlsx';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
import SelectSupplier from '../../components/steps/SelectSupplier';
import SelectInputFile from '../../components/steps/SelectInputFile';
import FileReadOptions, { IFileReadOptions } from '../../components/steps/FileReadOptions';
import Supplier from '../../entities/supplier';
import { ColumnType, InputRow, InputColumnHeader } from '../../entities/input';
import { parseNumber } from '../../helpers/number';
import { supplierValid } from '../../helpers/validateParams';

interface Props {
  xs: number;
  md: number;
  lg: number;

  supplier: Supplier;
  setSupplier: (supplier: Supplier) => void;

  setParsedInputFile: (file: InputRow[] | null) => void;
  setParsedInputFileHeaders: (headers: InputColumnHeader[] | null) => void;
}

function InputSelectPhase({
  xs, md, lg,
  supplier, setSupplier,
  setParsedInputFile,
  setParsedInputFileHeaders,
}: Props) {
  const [inputFile, setInputFile] = React.useState<File>();
  const [sheets, setSheets] = React.useState<string[]>([]);
  const [inputFileReadOptions, setInputFileReadOptions] = React.useState<IFileReadOptions>({
    columnNamesLine: 1,
    sheet: '',
  });
  const [hasRead, setHasRead] = React.useState(false);
  const [canChooseSupplier, setCanChooseSupplier] = React.useState(true);

  React.useEffect(() => {
    if (inputFile == null) return;
    inputFile?.arrayBuffer()
      .then((data) => {
        const workbook = XLSX.read(data, { raw: true });
        if (workbook.SheetNames.length === 0) throw new Error('Input workbook has no sheets');
        setSheets(workbook.SheetNames);
      });
  }, [inputFile]);

  React.useEffect(() => {
    if (hasRead) setHasRead(false);
  }, [inputFile, sheets, inputFileReadOptions]);

  return React.useMemo(() => {
    const canRead = supplierValid(supplier)
      && inputFile != null
      && sheets.includes(inputFileReadOptions.sheet);

    const handleRead = (): void => {
      inputFile?.arrayBuffer()
        .then((data) => {
          const workbook = XLSX.read(data, { raw: true });
          if (workbook.SheetNames.length === 0) throw new Error('Input workbook has no sheets');
          const sheet = workbook.Sheets[inputFileReadOptions.sheet];
          const json: InputRow[] = XLSX.utils.sheet_to_json(sheet, { header: 'A' })
            .slice(inputFileReadOptions.columnNamesLine - 1) as InputRow[];
          const header = json.splice(0, 1)[0];
          const parsedHeader: InputColumnHeader[] = Object.keys(header).map((h) => ({
            id: uuidv4(),
            name: header[h],
            key: h,
            type: json.every((row) => _.isFinite(parseNumber(row[h])))
              ? ColumnType.NUMBER : ColumnType.STRING,
          }));
          setParsedInputFile(json);
          setParsedInputFileHeaders(parsedHeader);
          setHasRead(true);

          // No longer allow to switch supplier once a file has been read
          // (to prevent state inconsistencies)
          setCanChooseSupplier(false);
        })
        .catch((error) => console.error('Could not parse input file', error));
    };

    return (
      <Grid item xs={12} container columnSpacing={3} rowSpacing={3}>
        <Grid item xs={xs} md={md} lg={lg}>
          <SelectSupplier
            supplier={supplier}
            setSupplier={setSupplier}
            enabled={canChooseSupplier}
          />
        </Grid>
        <Grid item xs={xs} md={md} lg={lg}>
          <SelectInputFile file={inputFile} setFile={setInputFile} />
        </Grid>
        <Grid item xs={xs} md={md} lg={lg}>
          <FileReadOptions
            options={inputFileReadOptions}
            setOptions={setInputFileReadOptions}
            canRead={canRead && !hasRead}
            handleRead={handleRead}
            sheets={sheets}
          />
        </Grid>
      </Grid>
    );
  }, [supplier, inputFile, inputFileReadOptions, sheets, hasRead, canChooseSupplier]);
}

export default InputSelectPhase;
