import { OutputRow, OutputColumnTemplate } from './output';

export enum ValidationErrorTypes {
  EmptyValues,
  TooLong,
  NotUnique,
  NotANumber,
  NotABoolean,
}

const ValidationErrorMessages = {
  [ValidationErrorTypes.EmptyValues]: 'Kolom is verplicht, maar niet alle rijen hebben hier iets ingevuld.',
  [ValidationErrorTypes.TooLong]: 'Kolom heeft een maximale lengte van {} karakters, maar sommige rijen gaan hier overheen.',
  [ValidationErrorTypes.NotUnique]: 'Alle waarden in deze kolom moeten {} uniek zijn, maar dat zijn ze niet allemaal.',
  [ValidationErrorTypes.NotANumber]: 'Alle waarden in deze kolom moeten een getal zijn, maar dat zijn ze niet allemaal.',
  [ValidationErrorTypes.NotABoolean]: 'Alle waarden in deze kolom moeten "Ja" of "Nee" zijn, maar dat zijn ze niet allemaal',
};

export interface ValidationInput {
  headers: OutputColumnTemplate[];
  rows: OutputRow[];
}

export type ValidationError = {
  type: ValidationErrorTypes;
  associatedColumns: string[];
  failingRows: OutputRow[];
  column: OutputColumnTemplate;
};

type ValidationErrorResult = {
  result: 'error',
  errors: ValidationError[];
};

type ValidationSuccessResult = {
  result: 'success',
};

export type ValidationResult = (ValidationErrorResult | ValidationSuccessResult) & {
  column: OutputColumnTemplate;
};

export function remCodeEmptyNotUnique(result: ValidationResult[]): ValidationResult[] {
  return result.map((r) => {
    if (r.result === 'success') return r;
    const errors = r.errors.filter((e) => !(r.column.key === 'B' && (e.type === ValidationErrorTypes.NotUnique || e.type === ValidationErrorTypes.EmptyValues)));
    if (errors.length === 0) {
      return {
        result: 'success',
        column: r.column,
      };
    }
    return {
      ...r,
      errors,
    };
  });
}

export function getErrorMessage(error: ValidationError): string {
  const msg = ValidationErrorMessages[error.type];

  if (error.type === ValidationErrorTypes.NotUnique) {
    if (error.associatedColumns.length === 0) {
      return msg.replace('{} ', '');
    }
    if (error.associatedColumns.length === 1) {
      return msg.replace('{}', `samen met kolom "${error.associatedColumns[0]}"`);
    }
    return msg.replace('{}', `samen met kolommen "${error.associatedColumns.join('", "')}"`);
  } if (error.type === ValidationErrorTypes.TooLong) {
    return msg.replace('{}', error.column.maxLength?.toString() || '???');
  }

  return msg;
}
