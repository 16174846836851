import React from 'react';
import { Alert, Box } from '@mui/material';
import { ArrowForward } from '@mui/icons-material';
import { InputColumnHeader } from '../entities/input';
import { DirectMapping, Merger } from '../entities/mappers';
import { outputColumns } from '../entities/output';

export interface TemplateErrors {
  template: InputColumnHeader[],
  file: InputColumnHeader[],
  failedMappings: DirectMapping[],
  failedMergers: Merger[],
}

interface Props {
  errors: TemplateErrors;
}

function TemplateSelectorErrors({ errors }: Props) {
  const fileErrors = () => {
    if (errors.file.length === 0) return null;
    return (
      <Alert severity="info">
        De volgende kolommen staan wel in het inputbestand,
        maar komen niet voor in het template. Ze zijn dus waarschijnlijk nieuw:
        <ul style={{ marginTop: '0.25rem' }}>
          {errors.file.map((e) => (<li key={e.id}>{e.name}</li>))}
        </ul>
      </Alert>
    );
  };

  const mappingErrors = () => {
    if (errors.template.length === 0 || errors.failedMappings.length === 0) return null;
    if (errors.template.length < errors.failedMappings.length) {
      throw new Error('Cannot have more mapping errors than (general) template errors!');
    }

    return (
      <>
        Hierdoor zijn de volgende
        {' '}
        <b>toewijzingen</b>
        {' '}
        verwijderd:
        <ul style={{ marginTop: '0.25rem' }}>
          {errors.failedMappings.map((m) => {
            const input = errors.template.find((h) => h.id === m.inputId);
            const output = outputColumns.find((c) => m.outputKeys.includes(c.key))!;
            return (
              <li>
                <Box sx={{ display: 'flex', gap: '0.25rem', alignItems: 'center' }}>
                  <span>{input ? input.name : '???'}</span>
                  <ArrowForward fontSize="small" />
                  <span>{output.name}</span>
                </Box>
              </li>
            );
          })}
        </ul>
      </>
    );
  };

  const mergerErrors = () => {
    if (errors.template.length === 0 || errors.failedMergers.length === 0) return null;
    if (errors.template.length < errors.failedMergers.length) {
      throw new Error('Cannot have more merger errors than (general) template errors!');
    }

    console.log(errors.template);
    console.log(errors.failedMergers);

    return (
      <>
        Hierdoor zijn de volgende
        {' '}
        <b>fusies/splits</b>
        {' '}
        gewijzigd:
        <ul style={{ marginTop: '0.25rem' }}>
          {errors.failedMergers.map((m) => {
            const inputs = m.inputIds
              .map((id) => errors?.template.find((c) => c.id === id)!);
            return (
              <li>
                <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                  <span>{inputs.map((i) => i.name).join(', ')}</span>
                  <span style={{ whiteSpace: 'nowrap', fontStyle: 'italic' }}>als input(s) van</span>
                  <span>{m.name}</span>
                </Box>
              </li>
            );
          })}
        </ul>
      </>
    );
  };

  const templateErrors = () => {
    if (errors.template.length === 0) return null;
    return (
      <Alert severity="error">
        De volgende kolommen staan net in het inputbestand,
        maar worden wel gebruikt in het template. Ze zijn dus
        waarschijnlijk verwijderd:
        <ul style={{ marginTop: '0.25rem' }}>
          {errors.template.map((e) => (<li key={e.id}>{e.name}</li>))}
        </ul>
        {mappingErrors()}
        {mergerErrors()}
      </Alert>
    );
  };

  return (
    <Box sx={{
      display: 'flex', flexDirection: 'column', gap: '1rem', marginTop: '1rem',
    }}
    >
      {fileErrors()}
      {templateErrors()}
    </Box>
  );
}

export default TemplateSelectorErrors;
