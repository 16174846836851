import {
  AggregateOperation, Merger, OutputOperationType, supportedOutputOperations,
} from '../entities/mappers';
import { ColumnType, InputColumnHeader } from '../entities/input';
import Supplier from '../entities/supplier';

export const allInputsNumbers = (
  merger: Merger,
  inputHeaders: InputColumnHeader[],
): boolean => (merger.inputIds
  .map((id) => inputHeaders.find((h) => h.id === id))
  .every((h) => h !== undefined && h.type === ColumnType.NUMBER));

export const aggregateOperationValid = (
  merger: Merger,
  inputHeaders: InputColumnHeader[],
): boolean => {
  const { aggregateOperation } = merger;
  if (aggregateOperation === AggregateOperation.AVERAGE) {
    return merger.inputIds.map((id) => inputHeaders.find((h) => h.id === id))
      .every((h) => h !== undefined && h.type === ColumnType.NUMBER);
  }
  return true;
};

export const outputOperationValid = (merger: Merger): boolean => {
  if (merger.outputOperation === undefined) return true;
  const { type, by, byRaw } = merger.outputOperation;

  return supportedOutputOperations[merger.aggregateOperation].includes(type)
    && Object.values(OutputOperationType).includes(type)
    && byRaw !== ''
    && !Number.isNaN(parseFloat(byRaw))
    && by.toString().length === byRaw.length;
};

export const supplierValid = (supplier: Supplier): boolean => supplier.code !== ''
    && !Number.isNaN(supplier.code)
    && supplier.name !== ''
    && supplier.itemCodeTemplate !== '';
