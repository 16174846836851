import React from 'react';
import {
  Divider, FormControl, InputLabel, MenuItem, Select,
} from '@mui/material';
import { Merger } from '../../entities/mappers';
import DuplicateError from './DuplicateError';
import { outputColumns } from '../../entities/output';
import { InputColumnHeader } from '../../entities/input';

interface Props {
  mergers: Merger[];
  index: number;
  input: InputColumnHeader;
  value: string[];
  changeValue: (input: InputColumnHeader, output: string | string[]) => void;
  duplicates: string[];
}

function OutputOrMergerSelect({
  mergers, index, input, value, changeValue, duplicates,
}: Props) {
  const usableMergers = mergers.filter((m) => m.name !== '');

  const renderValues = (values: string[]) => {
    const names: string[] = [];
    values.forEach((val) => {
      if (val.substring(0, 1) === 'c') {
        const column = outputColumns.find((c) => c.key === val.substring(2));
        if (column) names.push(column.name);
      } else if (val.substring(0, 1) === 'm') {
        const merger = mergers.find((m) => m.key === val.substring(2));
        if (merger) names.push(merger.name);
      }
    });

    return names.map((n, i): JSX.Element => (
      // eslint-disable-next-line react/no-array-index-key
      <React.Fragment key={`${n}-${i}`}>
        {n}
        {i < names.length - 1 && (
          <>
            ,
            <br />
          </>
        )}
      </React.Fragment>
    ));
  };

  return (
    <FormControl fullWidth error={duplicates.length > 0}>
      <InputLabel id={`output-${index}-label`}>Output kolom</InputLabel>
      <Select
        labelId={`output-${index}-label`}
        id={`output-${index}`}
        label="Output kolom"
        value={value}
        renderValue={renderValues}
        onChange={(event) => changeValue(input, event.target.value)}
        size="small"
        multiple
      >
        {outputColumns.filter((c) => !c.autoAssigned).map((c) => (
          <MenuItem value={`c-${c.key}`} key={c.key}>{c.name}</MenuItem>
        ))}
        {usableMergers.length > 0 && (<Divider />)}
        {usableMergers.map((m) => (
          <MenuItem value={`m-${m.key}`} key={m.key}>{m.name}</MenuItem>
        ))}
      </Select>
      <DuplicateError duplicates={duplicates} />
    </FormControl>
  );
}

export default OutputOrMergerSelect;
