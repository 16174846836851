import { updateDoc } from 'firebase/firestore';
import firebase from 'firebase/compat/app';
import { ValidationResult } from '../entities/validation';
import { CHUNK_SIZE, outputColumns, OutputRow } from '../entities/output';
import { calculateNewTemplate } from '../helpers/saveOutput';
import Supplier from '../entities/supplier';
import Firestore = firebase.firestore.Firestore;
import removeDuplicates, { RemoveDuplicatesResponse } from './removeDuplicates';
// eslint-disable-next-line import/no-named-default
import { default as validateOutputFunc } from './validateOutput';

export async function validateOutput(output: OutputRow[]): Promise<ValidationResult[]> {
  return new Promise((resolve) => {
    const result = validateOutputFunc(outputColumns, output);
    resolve(result);
  });
}

export async function saveOutput(
  db: Firestore,
  outputRows: OutputRow[],
  supplier: Supplier,
  name: string,
): Promise<void> {
  // Store rows in database
  const nrChunks = Math.ceil(outputRows.length / CHUNK_SIZE);
  const chunks: OutputRow[][] = [];
  for (let i = 0; i < nrChunks; i += 1) {
    chunks.push(outputRows.slice(i * CHUNK_SIZE, (i + 1) * CHUNK_SIZE));
  }

  const storedOutput = await db.collection('suppliers').doc(supplier.id).collection('outputs').add({
    name,
    createdAt: new Date(),
    updatedAt: new Date(),
  });

  console.debug(`Start saving chunks for ${supplier.name}...`);
  for (let i = 0; i < chunks.length; i += 1) {
    const chunk = chunks[i];
    // eslint-disable-next-line no-await-in-loop
    await db.collection('suppliers').doc(supplier.id)
      .collection('outputs').doc(storedOutput.id)
      .collection('records')
      .add({ chunk: JSON.stringify(chunk) });
    console.debug(`Saved chunk ${i + 1}/${chunks.length}!`);
  }

  // Update itemCodeTemplate for supplier
  const itemCodeTemplate = calculateNewTemplate(outputRows, supplier);
  const supplierRef = await db.collection('suppliers').doc(supplier.id);
  await updateDoc(supplierRef, {
    itemCodeTemplate,
  });
}

export default async function saveSupplierIfDoesntExist(
  db: Firestore,
  supplier: Supplier,
): Promise<Supplier> {
  const supplierDoc = await db.collection('suppliers').doc(supplier.id).get();
  if (supplierDoc.exists) return supplier;

  const supplierDoc2 = await db.collection('suppliers').add({
    name: supplier.name,
    code: supplier.code,
    itemCodeTemplate: supplier.itemCodeTemplate,
  });

  return {
    ...supplier,
    id: supplierDoc2.id,
  };
}

export async function removeDuplicatesFromOutput(
  output: OutputRow[],
): Promise<RemoveDuplicatesResponse> {
  return new Promise((resolve) => {
    resolve(removeDuplicates(output));
  });
}
