import React from 'react';
import {
  Box,
  Button,
  Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography,
} from '@mui/material';
import { OutputRow, outputColumns } from '../../entities/output';
import StepCard from '../StepCard';

interface Props {
  defaults: OutputRow | null;
  setDefaults: (rows: OutputRow) => void;
}

function DefaultColumns({ defaults, setDefaults }: Props) {
  return React.useMemo(() => {
    const disabled = defaults === undefined;

    const handleChange = (key: string, value: string) => {
      if (disabled) return;
      const defaultsNew = { ...defaults };
      defaultsNew[key] = value;
      setDefaults(defaultsNew);
    };

    const fillInSuggestions = () => {
      if (disabled) return;
      const defaultsNew = { ...defaults };
      outputColumns.filter((c) => c.default).forEach((c) => {
        defaultsNew[c.key] = c.default;
      });
      setDefaults(defaultsNew);
    };

    return (
      <StepCard step={6} title="Kies standaardwaarden" sx={{ marginTop: '1.5rem' }}>
        <Typography variant="body1">
          Kies hier waarden die worden ingevuld in de gegeven kolommen,
          als een cel in deze kolom leeg is in de output.
        </Typography>
        <Box
          sx={{ display: 'flex', my: '0.5rem', justifyContent: 'right' }}
          onClick={fillInSuggestions}
        >
          <Button
            variant="contained"
            disabled={disabled}
          >
            Vul suggesties in
          </Button>
        </Box>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Kolom</TableCell>
              <TableCell>Waarde</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {outputColumns
              .filter((c) => c.canHaveCustomDefault === undefined || c.canHaveCustomDefault)
              .map((c) => (
                <TableRow key={c.key}>
                  <TableCell>
                    {c.name}
                    {c.required && '*'}
                  </TableCell>
                  <TableCell>
                    <TextField
                      size="small"
                      value={defaults ? defaults[c.key] : ''}
                      placeholder={c.default ? c.default : ''}
                      onChange={(event) => handleChange(c.key, event.target.value)}
                      disabled={disabled}
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </StepCard>
    );
  }, [defaults]);
}

export default DefaultColumns;
