import React from 'react';
import firebase from 'firebase/compat/app';
import { Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Supplier from '../../entities/supplier';
import SelectSupplierForm from '../../components/SelectSupplierForm';
import { fetchOutputs } from '../../helpers/saveOutput';
import QueryDocumentSnapshot = firebase.firestore.QueryDocumentSnapshot;
import OutputListSelector from '../../components/OutputListSelector';
import { getOutputFromStorage } from '../../workers/assignItemCodes';
import getCsv from '../../workers/downloadOutput';

function MgmtDownloadSavedList() {
  const getInitialSupplier = (): Supplier => ({
    id: '-1',
    name: '',
    code: '',
    itemCodeTemplate: '',
  });

  const [loading, setLoading] = React.useState(false);
  const [supplier, setSupplier] = React.useState<Supplier>(getInitialSupplier);
  const [outputs, setOutputs] = React.useState<QueryDocumentSnapshot[] | null>(null);
  const [selectedOutput, setSelectedOutput] = React.useState('');

  React.useEffect(fetchOutputs(supplier, setOutputs), [supplier]);

  const downloadOutput = async () => {
    if (selectedOutput === '' || !outputs) return;

    setLoading(true);
    const list = outputs.find((o) => o.id === selectedOutput);
    if (!list) {
      setLoading(false);
      return;
    }
    const outputRows = await getOutputFromStorage(list);
    const encodedUri = getCsv(outputRows);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'prijslijst.csv');
    document.body.appendChild(link); // Required for FF

    link.click();
    document.body.removeChild(link);
    setLoading(false);
  };

  return (
    <Box>
      <SelectSupplierForm enabled supplier={supplier} setSupplier={setSupplier} noCreateNew />
      <Box sx={{ my: '0.5rem' }}>
        <OutputListSelector
          validationFailed={false}
          outputs={outputs}
          selectedOutput={selectedOutput}
          setSelectedOutput={setSelectedOutput}
          required
          name="Lijst"
        />
      </Box>
      <LoadingButton
        disabled={selectedOutput === ''}
        onClick={downloadOutput}
        loading={loading}
        variant="contained"
      >
        Download
      </LoadingButton>
    </Box>
  );
}

export default MgmtDownloadSavedList;
