import React from 'react';
import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { OutputRow } from '../entities/output';
import Supplier from '../entities/supplier';
import FirebaseContext from '../firebaseContext';
import saveSupplierIfDoesntExist, { saveOutput } from '../workers';

interface Props {
  outputRows: OutputRow[] | null;
  supplier: Supplier;
  setSupplier: (supplier: Supplier) => void;
  validationFailed: boolean;
  saved: boolean;
  setSaved: (saved: boolean) => void;
}

function SaveOutputNewModal({
  outputRows, supplier, setSupplier, validationFailed, saved, setSaved,
}: Props) {
  const { db } = React.useContext(FirebaseContext);
  const [open, setOpen] = React.useState(false);
  const [name, setName] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  const handleSave = async () => {
    if (outputRows == null || outputRows.length === 0) return;
    setLoading(true);

    try {
      const supplier2 = await saveSupplierIfDoesntExist(db, supplier);
      await saveOutput(db, outputRows, supplier2, name);
      setSupplier(supplier2);

      setLoading(false);
      setSaved(true);
      setOpen(false);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        disabled={validationFailed || saved}
        variant="contained"
        sx={{ mt: '0.5rem' }}
      >
        Sla output op
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth>
        <DialogTitle>Opslaan als nieuwe lijst</DialogTitle>
        <DialogContent>
          <TextField
            label="Naam"
            fullWidth
            margin="normal"
            value={name}
            onChange={(event) => setName(event.target.value)}
            error={!validationFailed && name === ''}
            disabled={validationFailed}
          />
        </DialogContent>
        <DialogActions>
          <LoadingButton
            variant="contained"
            disabled={validationFailed || name === ''}
            onClick={handleSave}
            loading={loading}
          >
            Sla output op
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default SaveOutputNewModal;
