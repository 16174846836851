import React from 'react';
import firebaseui from 'firebaseui';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { Box, Container } from '@mui/material';
import StyledFirebaseAuth from '../components/auth/StyledFirebaseUi';
import firebaseContext from '../firebaseContext';

function LoginView(): JSX.Element {
  const { auth } = React.useContext(firebaseContext);

  const uiConfig: firebaseui.auth.Config = {
    signInFlow: 'popup',
    callbacks: {
      signInSuccessWithAuthResult(): boolean {
        return false;
      },
    },
    signInOptions: [{
      provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
      disableSignUp: {
        status: true,
        adminEmail: 'contact@yoro.nu',
      },
    }],
  };

  return (
    <Box sx={{
      height: 'calc(100vh - 64px)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
    >
      <Container maxWidth="sm">
        <Box sx={{ width: '100%' }}>
          <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />
        </Box>
      </Container>
    </Box>
  );
}

export default LoginView;
