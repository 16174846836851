import React from 'react';
import { Alert, Box, Collapse } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { updateDoc } from 'firebase/firestore';
import SelectSupplierForm from '../../components/SelectSupplierForm';
import Supplier from '../../entities/supplier';
import { supplierValid } from '../../helpers/validateParams';
import FirebaseContext from '../../firebaseContext';

function MgmtUpdateSupplier() {
  const { db } = React.useContext(FirebaseContext);

  const getInitialSupplier = (): Supplier => ({
    id: '-1',
    name: '',
    code: '',
    itemCodeTemplate: '',
  });

  const [loading, setLoading] = React.useState(false);
  const [supplier, setSupplier2] = React.useState<Supplier>(getInitialSupplier);
  const [showSuccess, setShowSuccess] = React.useState(false);

  const setSupplier = (sup: Supplier, suc = false) => {
    setSupplier2(sup);
    setShowSuccess(suc);
  };

  const saveSupplier = async () => {
    if (!supplierValid(supplier)) return;
    setLoading(true);

    try {
      const supplierRef = await db.collection('suppliers').doc(supplier.id);
      await updateDoc(supplierRef, {
        name: supplier.name,
        code: supplier.code,
        itemCodeTemplate: supplier.itemCodeTemplate,
      });
      setSupplier(getInitialSupplier(), true);
    } catch (e) {
      console.error('Could not save new supplier.', e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      <Collapse in={showSuccess}>
        <Alert severity="success" sx={{ mb: 3 }}>
          Leverancier successvol opgeslagen!
          Vergeet niet het formulier te resetten om de wijzigingen te zien.
        </Alert>
      </Collapse>
      <SelectSupplierForm
        enabled
        edit
        supplier={supplier}
        setSupplier={setSupplier}
      />
      <LoadingButton
        sx={{ mt: 1 }}
        variant="contained"
        disabled={!supplierValid(supplier)}
        loading={loading}
        onClick={saveSupplier}
      >
        Aanpassen
      </LoadingButton>
    </Box>
  );
}

export default MgmtUpdateSupplier;
