import React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { Check, ErrorOutline } from '@mui/icons-material';
import { getErrorMessage, ValidationResult } from '../entities/validation';
import ValidationErrorDetails from './ValidationErrorDetails';

interface Props {
  validationResults: ValidationResult[] | null;
  loading?: boolean;
}

function OutputValidationResultList({
  validationResults, loading,
}: Props) {
  if (loading || validationResults == null) {
    return (
      <CircularProgress />
    );
  }

  return (
    <Box>
      {validationResults.map((val) => (
        <Box key={val.column.key} sx={{ display: 'flex', columnGap: '0.25rem' }}>
          {val.result === 'success' && (
          <>
            <Check color="success" />
            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>{val.column.name}</Typography>
          </>
          )}
          {val.result === 'error' && (
          <>
            <ErrorOutline color="error" />
            <Box>
              <Typography variant="body1" sx={{ fontWeight: 'bold' }}>{val.column.name}</Typography>
              {val.errors.map((error) => (
                <Typography variant="body1" key={error.type}>
                  {getErrorMessage(error)}
                  <ValidationErrorDetails error={error} column={val.column} />
                </Typography>
              ))}
            </Box>
          </>
          )}
        </Box>
      ))}
    </Box>
  );
}

export default OutputValidationResultList;
