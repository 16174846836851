import React from 'react';
import StepCard from '../StepCard';
import FileSelector from '../FileSelector';

interface Props {
  setFile: (file: File) => void
  file: File | undefined
}

function SelectInputFile({ setFile, file }: Props): JSX.Element {
  return (
    <StepCard step={2} title="Kies inputbestand">
      <FileSelector setFile={setFile} file={file} />
    </StepCard>
  );
}

export default SelectInputFile;
