import React from 'react';
import { Grid } from '@mui/material';
import firebase from 'firebase/compat/app';
import OutputValidation from '../../components/steps/OutputValidation';
import MatchOutput from '../../components/steps/MatchOutput';
import ExportOutput from '../../components/steps/ExportOutput';
import SaveWork from '../../components/steps/SaveWork';
import { OutputRow } from '../../entities/output';
import Supplier from '../../entities/supplier';
import Template, { TemplateOptions } from '../../entities/template';
import { ValidationResult } from '../../entities/validation';
import assignItemCodes from '../../workers/assignItemCodes';
import QueryDocumentSnapshot = firebase.firestore.QueryDocumentSnapshot;
import { validateOutput } from '../../workers';
import DeleteDuplicates from '../../components/steps/DeleteDuplicates';
import { InputListType } from '../../entities/input';

interface Props {
  xs: number;
  md: number;
  lg: number;

  supplier: Supplier;
  setSupplier: (supplier: Supplier) => void;
  template: Template | null;
  newTemplateOptions: TemplateOptions | null;

  parsedOutputFile: OutputRow[] | null;
  setParsedOutputFile: (output: OutputRow[] | null) => void,
}

function OutputPhase({
  xs, md,
  supplier, setSupplier, template, newTemplateOptions,
  parsedOutputFile, setParsedOutputFile,
}: Props) {
  const [parsedOutputValidation, setParsedOutputValidation] = React
    .useState<ValidationResult[] | null>(null);
  const [loadingValidation, setLoadingValidation] = React.useState(false);
  const [selectedOutputList, setSelectedOutputList] = React
    .useState<QueryDocumentSnapshot | undefined>(undefined);
  const [inputListType, setInputListType] = React.useState<InputListType>();

  React.useEffect(() => {
    if (!parsedOutputFile) return;
    setLoadingValidation(true);

    validateOutput(parsedOutputFile)
      .then((data) => setParsedOutputValidation(data))
      .catch((error) => console.error(error))
      .finally(() => setLoadingValidation(false));
  }, [parsedOutputFile]);

  return React.useMemo(() => {
    const handleItemCodeAssignment = async (outputs?: QueryDocumentSnapshot[]) => {
      if (!parsedOutputFile) return;
      const result = await assignItemCodes(parsedOutputFile, supplier.itemCodeTemplate, outputs);
      setParsedOutputFile(result);
    };

    const validationFailed = !parsedOutputValidation || parsedOutputValidation.some((v) => v.result === 'error');

    return (
      <Grid item xs={12} container columnSpacing={3} rowSpacing={3}>

        <Grid item xs={xs} md={md} lg={6}>
          <OutputValidation
            validationResults={parsedOutputValidation}
            loading={loadingValidation}
          />
          <DeleteDuplicates
            parsedOutputFile={parsedOutputFile}
            setParsedOutputFile={setParsedOutputFile}
            validationFailed={validationFailed}
          />
        </Grid>

        <Grid item xs={xs} md={md} lg={6}>
          <MatchOutput
            validationResult={parsedOutputValidation}
            assignItemCodes={handleItemCodeAssignment}
            supplier={supplier}
            setSelectedOutputList={setSelectedOutputList}
            inputListType={inputListType}
            setInputListType={setInputListType}
            outputRows={parsedOutputFile || []}
          />
          <ExportOutput
            validationResult={parsedOutputValidation}
            outputRows={parsedOutputFile}
          />
          <SaveWork
            validationFailed={validationFailed}
            outputRows={parsedOutputFile}
            supplier={supplier}
            setSupplier={setSupplier}
            oldTemplate={template}
            newTemplateOptions={newTemplateOptions}
            selectedOutputList={selectedOutputList}
            inputListType={inputListType}
          />
        </Grid>
      </Grid>
    );
  }, [parsedOutputFile, parsedOutputValidation, supplier, inputListType, selectedOutputList]);
}

export default OutputPhase;
