import React from 'react';
import {
  Box, FormControl, InputLabel, MenuItem, Select, TextField,
} from '@mui/material';
import {
  collection, getDocs, query, QueryDocumentSnapshot,
} from 'firebase/firestore';
import Supplier from '../entities/supplier';
import FirebaseContext from '../firebaseContext';

interface Props {
  enabled: boolean;
  noSelectExisting?: boolean;
  noCreateNew?: boolean;
  edit?: boolean;

  supplier: Supplier | undefined
  setSupplier: (supplier: Supplier) => void
}

function SelectSupplierForm({
  enabled, noSelectExisting, noCreateNew, edit, supplier, setSupplier,
}: Props) {
  const { db } = React.useContext(FirebaseContext);
  const [suppliers, setSuppliers] = React.useState<QueryDocumentSnapshot[] | null>(null);

  const handleSupplierChange = (id: string): void => {
    if (suppliers == null) return;
    if (id === '-1') {
      setSupplier({
        id: '-1',
        name: '',
        code: '',
        itemCodeTemplate: '',
      });
      return;
    }
    const index = suppliers.findIndex((s) => s.id === id);
    setSupplier({
      id: suppliers[index].id,
      name: suppliers[index].get('name'),
      code: suppliers[index].get('code'),
      itemCodeTemplate: suppliers[index].get('itemCodeTemplate'),
    });
  };

  React.useEffect(() => {
    if (!noSelectExisting) {
      getDocs(query(collection(db, 'suppliers')))
        .then((result) => {
          const supps = result.docs.sort((a, b) => {
            if (a.get('name') < b.get('name')) return -1;
            if (a.get('name') > b.get('name')) return 1;
            return 0;
          });
          setSuppliers(supps);
        })
        .catch((error) => console.error('Could not fetch suppliers', error));
    } else {
      setSuppliers([]);
    }
  }, [noSelectExisting]);

  const handleSupplierNameChange = (name: string): void => {
    if ((supplier == null) || supplier.id !== '-1') return;
    setSupplier({
      ...supplier,
      name,
    });
  };

  const handleSupplierCodeChange = (code: string): void => {
    if ((supplier == null) || supplier.id !== '-1') return;
    setSupplier({
      ...supplier,
      code,
    });
  };

  const handleItemCodeTemplateChange = (itemCodeTemplate: string): void => {
    if ((supplier == null) || (supplier.id !== '-1' && !edit)) return;
    setSupplier({
      ...supplier,
      itemCodeTemplate,
    });
  };

  const fieldsDisabled = (supplier && supplier.id !== '-1' && !edit) || !enabled;

  return (
    <Box>
      {!noSelectExisting && (
        <FormControl fullWidth required error={!supplier || supplier.id === ''} disabled={!enabled}>
          <InputLabel id="select-supplier-label">Leverancier</InputLabel>
          <Select
            labelId="select-supplier-label"
            id="select-supplier"
            value={supplier?.id || ''}
            label="Leverancier"
            onChange={(event) => handleSupplierChange(event.target.value)}
          >
            {(!noCreateNew && !edit) && (
              <MenuItem value="-1">Nieuwe leverancier</MenuItem>
            )}
            {suppliers?.map((s) => (
              <MenuItem key={s.id} value={s.id}>{s.get('name')}</MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      {!noCreateNew && supplier && (
        <>
          <TextField
            fullWidth
            sx={{ marginTop: '1rem' }}
            value={supplier.name}
            label="Naam leverancier"
            disabled={fieldsDisabled}
            required={supplier.id === '-1'}
            error={supplier.id === '-1' && supplier.name === ''}
            onChange={(event) => handleSupplierNameChange(event.target.value)}
          />
          <TextField
            fullWidth
            sx={{ marginTop: '1rem' }}
            value={supplier.code}
            label="Leveranciercode"
            disabled={fieldsDisabled}
            required={supplier.id === '-1'}
            type="number"
            error={supplier.id === '-1' && supplier.code === ''}
            onChange={(event) => handleSupplierCodeChange(event.target.value)}
          />
          <TextField
            fullWidth
            sx={{ marginTop: '1rem' }}
            value={supplier.itemCodeTemplate}
            label="Artikelnummer start"
            disabled={fieldsDisabled}
            required={supplier.id === '-1'}
            error={supplier.id === '-1' && supplier.itemCodeTemplate === ''}
            onChange={(event) => handleItemCodeTemplateChange(event.target.value)}
            helperText="Nummer van het eerste artikel in de lijst. Voor de overige artikelen zonder nummer, wordt dit nummer doorgeteld."
          />
        </>
      )}
    </Box>
  );
}

export default SelectSupplierForm;
