import React from 'react';
import { Box } from '@mui/material';
import { Merger } from '../../entities/mappers';
import MergerInputOrderCard from './MergerInputOrderCard';
import { InputColumnHeader } from '../../entities/input';

interface Props {
  inputHeaders: InputColumnHeader[];
  merger: Merger;
  updateInputOrder: (inputs: string[]) => void;
}

function MergerInputOrderContainer({ inputHeaders, merger, updateInputOrder }: Props) {
  const [inputIds, setInputIds] = React.useState(merger.inputIds);

  React.useEffect(() => {
    setInputIds(merger.inputIds);
  }, [merger.inputIds]);

  const moveCard = React.useCallback((dragIndex: number, hoverIndex: number) => {
    setInputIds((prevInputs: string[]) => {
      const newInputs = [...prevInputs];
      newInputs.splice(dragIndex, 1);
      newInputs.splice(hoverIndex, 0, prevInputs[dragIndex]);
      return newInputs;
    });
  }, []);

  const handleDrop = () => {
    updateInputOrder(inputIds);
  };

  const renderInput = React.useCallback((inputId: string, index: number) => (
    <MergerInputOrderCard
      inputHeaders={inputHeaders}
      key={inputId}
      index={index}
      inputId={inputId}
      moveInput={moveCard}
      handleDrop={handleDrop}
    />
  ), [inputIds]);

  return (
    <Box sx={{ paddingTop: '0.5rem' }}>
      {inputIds.map((id, i) => renderInput(id, i))}
    </Box>
  );
}

export default MergerInputOrderContainer;
