import React from 'react';
import {
  Alert, Box, Button, Divider, FormControl, InputLabel, MenuItem, Select,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import firebase from 'firebase/compat/app';
import { Delete } from '@mui/icons-material';
import StepCard from '../StepCard';
import { remCodeEmptyNotUnique, ValidationResult } from '../../entities/validation';
import Supplier from '../../entities/supplier';
import OutputListSelector from '../OutputListSelector';
import { fetchOutputs } from '../../helpers/saveOutput';
import { InputListType } from '../../entities/input';
import { codeColumnKey, OutputRow } from '../../entities/output';
import QueryDocumentSnapshot = firebase.firestore.QueryDocumentSnapshot;
import { matchOutputs } from '../../workers/assignItemCodes';
import MatchingErrorDetails from '../MatchingErrorDetails';
import DocumentData = firebase.firestore.DocumentData;

interface Props {
  validationResult: ValidationResult[] | null;
  assignItemCodes: (outputs?: QueryDocumentSnapshot[]) => Promise<void>;
  supplier: Supplier;
  setSelectedOutputList: (output?: QueryDocumentSnapshot) => void;

  inputListType?: InputListType;
  setInputListType: (type: InputListType) => void;

  outputRows: OutputRow[];
}

function MatchOutput({
  validationResult, assignItemCodes, supplier, setSelectedOutputList,
  inputListType, setInputListType, outputRows,
}: Props) {
  const [loading, setLoading] = React.useState(false);
  const [outputs, setOutputs] = React.useState<QueryDocumentSnapshot[] | null>(null);
  const [selectedOriginalList, setSelectedOriginalList] = React.useState('');
  const [selectedOldLists, setSelectedOldLists] = React.useState<string[]>(['']);

  const setSelectedOldList = (index: number) => (list: string) => {
    const newSelection = [...selectedOldLists];
    newSelection[index] = list;
    setSelectedOldLists(newSelection);
  };

  const deleteSelectedOldList = (index: number) => {
    const newSelection = [...selectedOldLists];
    newSelection.splice(index, 1);
    setSelectedOldLists(newSelection);
  };

  const addSelectedOldList = () => {
    const newSelection = [...selectedOldLists];
    newSelection.push('');
    setSelectedOldLists(newSelection);
  };

  const [matchingFailedRows, setMatchingFailedRows] = React.useState<OutputRow[]>([]);

  const validationFailed = validationResult == null || remCodeEmptyNotUnique(validationResult).some((r) => r.result === 'error');
  const validationSuccessful = validationResult != null && validationResult.every((res) => res.result === 'success');

  React.useEffect(fetchOutputs(supplier, setOutputs), [supplier]);
  React.useEffect(() => {
    setSelectedOriginalList('');
    setSelectedOldLists(['']);
  }, [inputListType]);

  const handleMatching = async () => {
    if (outputs == null) return;
    setLoading(true);

    const original = outputs.find((o) => o.id === selectedOriginalList);
    const list = selectedOldLists.map((selectedList) => outputs.find((o) => o.id === selectedList))
      .filter((l) => l !== undefined) as QueryDocumentSnapshot<DocumentData>[];
    if (original) list.push(original);

    try {
      if (inputListType === InputListType.PART) {
        // If input list type is PART, we first need to make sure there are no
        // duplicates in this new list
        if (!original) throw new Error('Original list is not set when using a PART input list type.');
        const duplicates = (await matchOutputs(original, structuredClone(outputRows)))
          .filter((r) => r[codeColumnKey] !== undefined);
        // If there are, show them to the user.
        if (duplicates.length > 0) {
          setMatchingFailedRows(duplicates);
          setLoading(false);
          return;
        }
        // Otherwise, start matching based on the old list (if it is defined)
        await assignItemCodes(list.length > 0 ? list : []);
      } else {
        await assignItemCodes(list);
      }

      setSelectedOutputList(original);
    } catch (e) {
      console.error(e);
    }

    setLoading(false);
  };

  const getInfoAboutSelection = () => {
    switch (inputListType) {
      case InputListType.NEW:
        return (
          <Alert severity="info">
            De lijst waar je nu mee werkt is een nieuwe prijslijst.
            Optioneel kun je deze nieuwe lijst matchen met een oude lijst.
            Wanneer je deze nieuwe lijst matcht, worden reeds bestaande producten bijgewerkt met
            de gegevens uit de nieuwe lijst (denk hierbij aan prijzen, beschrijvingen, etc).
            De oude lijst wordt niet aangepast.
          </Alert>
        );
      case InputListType.ADDENDUM:
        return (
          <Alert severity="info">
            De lijst waar je nu mee werkt is een (kleine) wijziging op een al bestaande lijst.
            Kies de originele lijst waar de nieuwe lijst een wijziging op is.
            Bestaande producten worden dan bijgewerkt met de gegevens uit de
            nieuwe lijst (denk hierbij aan prijzen, beschrijvingen, etc). Niet-bestaande
            producten worden toegevoegd.
            Optioneel kun je nog een oude lijst kiezen. Alle producten die niet voorkomen in de
            originele lijst, worden dan eerst gematcht met de oude lijst, alvorens ze worden
            toegevoegd aan de originele lijst.
            LET OP: de output bevat alleen de producten die op de nieuwe lijst staan.
            Deze kun je gewoon importeren in Exact. Als je de lijst opslaat bij stap 13,
            wordt de originele lijst bijgewerkt met deze wijzigingen.
          </Alert>
        );
      case InputListType.PART:
        return (
          <Alert severity="info">
            De lijst waar je nu mee werkt is een nieuw deel van een bestaande lijst,
            bijvoorbeeld een tweede Excel blad.
            Kies de originele lijst waar deze nieuwe lijst onderdeel van is.
            Deze lijst wordt als het ware &quot;achter de originele lijst geplakt&quot;.
            Bij dubbele producten krijg je een foutmelding.
            Optioneel kun je nog een oude lijst kiezen. Alle producten die niet voorkomen in de
            originele lijst, worden dan eerst gematcht met de oude lijst, alvorens ze worden
            toegevoegd aan de originele lijst.
            LET OP: de output bevat alleen de producten die op de nieuwe lijst staan.
            Deze kun je gewoon importeren in Exact. Als je de lijst opslaat bij stap 13,
            wordt de originele lijst bijgewerkt met deze wijzigingen.
          </Alert>
        );
      default: return null;
    }
  };

  return (
    <StepCard
      step={11}
      title="Artikelnummers toewijzen"
      actions={(
        <LoadingButton
          variant="contained"
          disabled={validationFailed || validationSuccessful
            // No list type selected
            || inputListType === undefined
            // Selected list type requires selection of old list
            || ((inputListType === InputListType.PART || inputListType === InputListType.ADDENDUM) && selectedOriginalList === '')
            || (selectedOldLists.some((l) => l === selectedOriginalList) && selectedOriginalList !== '')}
          onClick={handleMatching}
          loading={loading}
        >
          Toewijzen
        </LoadingButton>
    )}
    >
      <MatchingErrorDetails
        rows={matchingFailedRows}
        open={matchingFailedRows.length > 0}
        handleClose={() => setMatchingFailedRows([])}
      />
      <Box>
        <FormControl
          fullWidth
          margin="normal"
          disabled={validationFailed || validationSuccessful}
          required
          error={inputListType === undefined}
        >
          <InputLabel id="select-input-type-label">Type input lijst</InputLabel>
          <Select
            labelId="select-input-type-label"
            id="select-input-type"
            label="Type input lijst"
            value={inputListType || ''}
            onChange={(event) => setInputListType(event.target.value as any)}
          >
            <MenuItem value={InputListType.NEW}>
              Geheel nieuwe prijslijst (nieuwe leverancier of nieuwe versie)
            </MenuItem>
            <MenuItem value={InputListType.ADDENDUM}>
              Wijziging op bestaande prijslijst (addendum)
            </MenuItem>
            <MenuItem value={InputListType.PART}>
              Volgende deel (pagina, blad, etc) van bestaande prijslijst
            </MenuItem>
          </Select>
        </FormControl>
        {getInfoAboutSelection()}
        <Divider sx={{ my: '1rem' }} />
        {inputListType !== InputListType.NEW && (
          <OutputListSelector
            disabled={validationSuccessful}
            validationFailed={validationFailed}
            required
            error={selectedOldLists.some((l) => l === selectedOriginalList) && selectedOriginalList !== ''}
            outputs={outputs}
            selectedOutput={selectedOriginalList}
            setSelectedOutput={setSelectedOriginalList}
            name="Originele lijst"
          />
        )}
        {selectedOldLists.map((selectedOldList, i) => (
          <Box
            key={selectedOldList}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '1rem',
              marginTop: '0.5rem',
            }}
          >
            <Box sx={{ flexGrow: 1, width: '100%' }}>
              <OutputListSelector
                disabled={inputListType === undefined || validationSuccessful}
                validationFailed={validationFailed}
                outputs={outputs}
                error={selectedOldLists.some((l) => l === selectedOriginalList) && selectedOldList !== ''}
                selectedOutput={selectedOldList}
                setSelectedOutput={setSelectedOldList(i)}
                oldListWarning={inputListType === InputListType.NEW ? 1 : 2}
              />
            </Box>
            {i > 0 && (
            <Box>
              <Button variant="contained" color="error" onClick={() => deleteSelectedOldList(i)}>
                <Delete />
              </Button>
            </Box>
            )}
          </Box>
        ))}
        <Button sx={{ marginTop: '0.5rem' }} variant="contained" onClick={addSelectedOldList}>
          Extra lijst
        </Button>
      </Box>
    </StepCard>
  );
}

export default MatchOutput;
