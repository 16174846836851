import React from 'react';
import { Box, Typography } from '@mui/material';
import StepCard from '../StepCard';
import { ValidationResult } from '../../entities/validation';
import OutputValidationResultList from '../OutputValidationResultList';

interface Props {
  validationResults: ValidationResult[] | null;
  loading: boolean;
}

function OutputValidation({ validationResults, loading }: Props) {
  return React.useMemo(() => {
    if (validationResults == null && !loading) {
      return (
        <StepCard step={9} title="Valideer output">
          <Typography variant="body1" />
        </StepCard>
      );
    }
    return (
      <StepCard step={9} title="Valideer output">
        <Box sx={{
          display: 'flex', flexDirection: 'column', width: '100%', gap: '0.5rem',
        }}
        >
          <Typography sx={{ fontStyle: 'italic', marginBottom: '1rem' }}>
            Los alle onderstaande problemen op, voordat je weer verder gaat.
            De problemen met kolom &ldquo;code&ldquo; hoef je niet op te lossen;
            deze worden (als het goed is) automatisch opgelost bij stap 10.
          </Typography>
          <OutputValidationResultList validationResults={validationResults} loading={loading} />
        </Box>
      </StepCard>
    );
  }, [validationResults]);
}

export default OutputValidation;
