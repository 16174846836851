import React from 'react';
import {
  FormControl, InputLabel, MenuItem, Select, TextField, Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import StepCard from '../StepCard';
import { isInteger } from '../../helpers/number';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IFileReadOptions {
  columnNamesLine: number
  sheet: string;
}

interface Props {
  options: IFileReadOptions
  setOptions: (options: IFileReadOptions) => void
  canRead: boolean
  handleRead: () => void
  sheets: string[]
}

function FileReadOptions({
  options, setOptions, canRead, handleRead, sheets,
}: Props): JSX.Element {
  const [loading, setLoading] = React.useState(false);
  const [rawColumnNamesLineValue, setRawColumnNamesLineValue] = React.useState(
    options.columnNamesLine.toString(),
  );

  const handleSheetChange = (sheet: string): void => {
    setOptions({
      ...options,
      sheet,
    });
  };

  const handleColumnNamesLineChange = (value: string): void => {
    if (!isInteger(value)) {
      setRawColumnNamesLineValue('');
    } else {
      const int = parseInt(value, 10);
      setOptions({
        ...options,
        columnNamesLine: int,
      });
      setRawColumnNamesLineValue(int.toString());
    }
  };

  return (
    <StepCard
      step={3}
      title="Leesopties"
      actions={(
        <LoadingButton
          variant="contained"
          color="primary"
          disabled={!canRead || !isInteger(rawColumnNamesLineValue)}
          onClick={() => {
            setLoading(true);
            handleRead();
            setLoading(false);
          }}
          loading={loading}
        >
          Lees input
        </LoadingButton>
      )}
    >
      <FormControl
        fullWidth
        margin="dense"
        required
        error={sheets.length > 0 && !sheets.includes(options.sheet)}
        disabled={sheets.length === 0}
      >
        <InputLabel id="input-sheet-select-label">Blad</InputLabel>
        <Select
          labelId="input-sheet-select-label"
          id="input-sheet-select"
          value={options.sheet}
          label="Blad"
          onChange={(event) => handleSheetChange(event.target.value)}
        >
          {sheets.map((sheet) => (
            <MenuItem key={sheet} value={sheet}>{sheet}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <Typography variant="body2" sx={{ fontStyle: 'italic', marginTop: '1rem' }}>
        De data in het inputbestand hoeft niet op de eerste regel te beginnen.
        Kies hier het regelnummer, waarop de kolomnamen staan. Standaard is dit regel 1.
        Ik ga ervan uit dat de data dan op de regel na deze regel begint.
        Standaard is dit dus 2.
      </Typography>
      <TextField
        fullWidth
        label="Regel kolomnamen"
        value={rawColumnNamesLineValue}
        onChange={(event) => setRawColumnNamesLineValue(event.target.value)}
        onBlur={(event) => handleColumnNamesLineChange(event.target.value)}
        error={rawColumnNamesLineValue === ''}
        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
        margin="dense"
      />
    </StepCard>
  );
}

export default FileReadOptions;
