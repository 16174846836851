import { ColumnType } from './input';

export enum AggregateOperation {
  APPEND = 'append',
  APPEND_CUTOFF = 'append_cutoff',
  AVERAGE = 'average',
}

type AggregateOperationDescription = {
  [key in AggregateOperation]: {
    columnTypes: ColumnType[],
    explanation: string,
  };
};

export const aggregateOperations: AggregateOperationDescription = {
  [AggregateOperation.APPEND]: {
    columnTypes: [ColumnType.STRING, ColumnType.NUMBER],
    explanation: 'Inputs achter elkaar plakken',
  },
  [AggregateOperation.APPEND_CUTOFF]: {
    columnTypes: [ColumnType.STRING, ColumnType.NUMBER],
    explanation: 'Inputs achter elkaar plakken (woorden afkappen)',
  },
  [AggregateOperation.AVERAGE]: {
    columnTypes: [ColumnType.NUMBER],
    explanation: 'Gemiddelde nemen van inputs',
  },
};

export enum OutputOperationType {
  ADD = 'add',
  MULTIPLY = 'multiply',
}

type OutputOperationExplanation = {
  [key in OutputOperationType]: string;
};

export const outputOperationExplanations: OutputOperationExplanation = {
  [OutputOperationType.ADD]: 'Getal optellen bij alle outputs',
  [OutputOperationType.MULTIPLY]: 'Getal vermenigvuldigen met alle outputs',
};

export type OutputOperation = {
  type: OutputOperationType
  by: number
  byRaw: string
};

export type Splitter = {
  nrOfCharacters: number
  rawNrOfCharacters: string
  outputColumnKeys: string[]
};

export type Merger = {
  name: string
  key: string
  inputIds: string[]
  outputs: Splitter[]
  aggregateOperation: AggregateOperation
  outputOperation?: OutputOperation
};

export type DirectMapping = {
  inputId: string
  outputKeys: string[]
};

type SupportedOutputOperations = {
  [key in AggregateOperation]: OutputOperationType[];
};

export const supportedOutputOperations: SupportedOutputOperations = {
  [AggregateOperation.APPEND]: [],
  [AggregateOperation.APPEND_CUTOFF]: [],
  [AggregateOperation.AVERAGE]: [
    OutputOperationType.ADD,
    OutputOperationType.MULTIPLY,
  ],
};
