export enum InputListType {
  NEW = 'new',
  PART = 'part',
  ADDENDUM = 'addendum',
}

export enum ColumnType {
  STRING = 'string',
  NUMBER = 'number',
}

export interface InputColumnHeader {
  id: string;
  name: string | number;
  key: string; // Key A, B, C, ...
  type: ColumnType;
}

export interface InputRow {
  [key: string]: string;
}

export function columnTypeSet(columnTypes: ColumnType[]): ColumnType {
  if (columnTypes.every((t) => t === ColumnType.NUMBER)) return ColumnType.NUMBER;
  return ColumnType.STRING;
}
