import React from 'react';
import firebase from 'firebase/compat/app';
import {
  Box, FormControl, InputLabel, MenuItem, Select,
} from '@mui/material';
import Fuse from 'fuse.js';
import IFuseOptions = Fuse.IFuseOptions;
import QueryDocumentSnapshot = firebase.firestore.QueryDocumentSnapshot;
import StepCard from '../StepCard';
import Supplier from '../../entities/supplier';
import Template, { TemplateOptions } from '../../entities/template';
import { InputColumnHeader } from '../../entities/input';
import { DirectMapping, Merger } from '../../entities/mappers';
import TemplateSelectorErrors from '../TemplateSelectorErrors';

interface Props {
  supplier: Supplier;
  template: Template | null;
  setTemplate: (template: Template, matchedInputColumns?: InputColumnHeader[]) => void;
  inputFileHeaders: InputColumnHeader[] | null;
  hasInputRows: boolean;
}

function TemplateSelector({
  supplier, template, setTemplate, inputFileHeaders, hasInputRows,
}: Props): JSX.Element {
  const [templates, setTemplates] = React.useState<QueryDocumentSnapshot[] | null>();
  const [errors, setErrors] = React.useState<{
    template: InputColumnHeader[],
    file: InputColumnHeader[],
    failedMappings: DirectMapping[],
    failedMergers: Merger[],
  }>();

  const handleTemplateChange = (id: string): void => {
    if (templates == null || inputFileHeaders == null) return;
    if (id === '-1') {
      setTemplate({
        id: '-1',
        createdAt: new Date(),
        name: '',
        options: {
          inputColumns: [],
          defaultOutputColumns: {},
          mergers: [],
          mappings: [],
        },
      });
      setErrors(undefined);
      return;
    }

    const index = templates.findIndex((t) => t.id === id);
    const selected = templates[index];
    const options = selected.get('options') as TemplateOptions;

    // Keep track of any leftovers of the intersection
    const remainingFromFile: InputColumnHeader[] = [];
    const remainingFromTemplate: InputColumnHeader[] = options.inputColumns
      .map((h) => ((typeof h.name === 'string') ? h : { ...h, name: h.name.toString() }));

    const fuseOptions: IFuseOptions<InputColumnHeader> = {
      includeScore: true,
      shouldSort: true,
      threshold: 0.1,
      keys: ['name'],
    };

    // Assign the template column ID to an input column when they match.
    // Keep the random ID if there are no matches.
    const matchedInputColumns = inputFileHeaders.map((c) => {
      const fuse = new Fuse(remainingFromTemplate, fuseOptions);
      const result = fuse.search(typeof c.name === 'string' ? c.name : c.name.toString());
      const match = result[0];
      if (match) {
        // eslint-disable-next-line no-param-reassign
        c.id = match.item.id;
        const indexToRemove = remainingFromTemplate.findIndex((h) => h.id === match.item.id);
        remainingFromTemplate.splice(indexToRemove, 1);
      } else {
        // eslint-disable-next-line no-param-reassign
        remainingFromFile.push(c);
      }
      return c;
    });

    // Any leftover columns from the template that are also included in mergers or mappings
    const failedMatches = remainingFromTemplate
      .filter((c) => options.mappings.some((m) => m.inputId === c.id)
        || options.mergers.some((m) => m.inputIds.includes(c.id)));
    const idsToRemove = failedMatches.map((m) => m.id);

    // Create a list of all mappings that are not possible with the given input file,
    // because the column is missing. Remove these from the template.
    const failedMappings = options.mappings.filter((m) => idsToRemove.includes(m.inputId));
    options.mappings = options.mappings.filter((m) => !idsToRemove.includes(m.inputId));

    // Create a list of all mergers that are not possible with the given input file,
    // because a missing column is included in the input.
    // Then, remove this input from the input's merger
    const failedMergers = options.mergers.filter((m) => m.inputIds
      .some((id2) => idsToRemove.includes(id2)))
      .map((m) => {
        const newMerger: Merger = { ...m };
        newMerger.inputIds = newMerger.inputIds.filter((id2) => idsToRemove.includes(id2));
        return newMerger;
      });
    options.mergers = options.mergers.map((m) => {
      const newMerger: Merger = { ...m };
      newMerger.inputIds = newMerger.inputIds.filter((id2) => !idsToRemove.includes(id2));
      return newMerger;
    });

    setErrors({
      template: failedMatches,
      file: remainingFromFile,
      failedMappings,
      failedMergers,
    });
    setTemplate({
      id: selected.id,
      createdAt: selected.get('createdAt'),
      name: selected.get('name'),
      options,
    }, matchedInputColumns);
  };

  React.useEffect(() => {
    if (supplier.id === '') {
      setTemplates(null);
      return;
    } if (supplier.id === '-1') {
      setTemplates([]);
      return;
    }

    const supplierRef = firebase.firestore().doc(`suppliers/${supplier.id}`);

    firebase.firestore().collectionGroup('templates')
      .orderBy(firebase.firestore.FieldPath.documentId())
      .startAt(supplierRef.path)
      .endAt(`${supplierRef.path}\uf8ff`)
      .get()
      .then((querySnapshot) => {
        setTemplates(querySnapshot.docs);
      })
      .catch((err) => {
        console.error('Failed to execute query', err);
      });
  }, [supplier]);

  let menuOptions: JSX.Element[] = [];
  if (templates != null) {
    menuOptions = templates
      .sort((a, b) => b.get('createdAt').toDate().getTime() - a.get('createdAt').toDate().getTime())
      .map((s) => {
        const name = s.get('name');
        return (
          <MenuItem key={s.id} value={s.id}>
            {s.get('createdAt').toDate().toLocaleString()}
            {name ? ` (${name})` : ''}
          </MenuItem>
        );
      });
    menuOptions.push((<MenuItem value="-1" key="-1">Nieuw template</MenuItem>));
  }

  return (
    <StepCard step={5} title="Kies template">
      <Box>
        <FormControl fullWidth required>
          <InputLabel id="select-template-label">Template</InputLabel>
          <Select
            labelId="select-template-label"
            id="select-template"
            value={template != null ? template.id : ''}
            label="Template"
            disabled={inputFileHeaders == null || !hasInputRows}
            onChange={(event) => handleTemplateChange(event.target.value)}
          >
            {menuOptions}
          </Select>
        </FormControl>
      </Box>
      {errors != null && (<TemplateSelectorErrors errors={errors} />)}
    </StepCard>
  );
}

export default TemplateSelector;
