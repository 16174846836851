import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Box, Button, Typography } from '@mui/material';
import { Add } from '@mui/icons-material';
import StepCard from '../StepCard';
import { AggregateOperation, DirectMapping, Merger } from '../../entities/mappers';
import MergerSettings from '../mapping/MergerSettings';
import { InputColumnHeader } from '../../entities/input';

interface Props {
  mergers: Merger[];
  setMergers: (mergers: Merger[]) => void;
  directMappings: DirectMapping[];
  inputHeaders: InputColumnHeader[] | null;
}

function InputMergeSplit({
  mergers, setMergers, directMappings, inputHeaders,
}: Props) {
  const addMerger = () => {
    setMergers([
      ...mergers,
      {
        name: '',
        key: uuidv4(),
        inputIds: [],
        outputs: [],
        aggregateOperation: AggregateOperation.APPEND,
      },
    ]);
  };

  const updateMerger = (merger: Merger, index: number) => {
    const m = [...mergers];
    m[index] = merger;
    setMergers(m);
  };

  const removeMerger = (index: number) => {
    const m = [...mergers];
    m.splice(index, 1);
    setMergers(m);
  };

  return (
    <StepCard step="7b" title="Voeg kolommen samen">
      <Box sx={{ marginBottom: '1rem' }}>
        <Typography variant="body1">
          Soms moeten meerdere kolommen samengevoegd worden tot één kolom,
          of is en kolom te lang en moet deze opgesplitst worden (of een
          combinatie van beiden). Je kunt hier deze tool voor gebruiken.
          Eerst maak je een nieuw &quot;blok&quot; aan. Geef deze een duidelijke naam.
          Je kunt dan één of meerdere outputs aanmaken en bepalen hoeveel karakters
          er maximaal in deze kolom mogen. Alle overgebleven tekst gaat dan naar de
          volgende kolom in de rij. Als je geen maximaal aantal karakters invult,
          gaat alle overgebleven tekst naar deze kolom. Bij stap 6a kun je kiezen
          welke kolommen als input gelden voor een blok.
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'right', mb: '1rem' }}>
        <Button
          variant="contained"
          startIcon={<Add />}
          onClick={addMerger}
        >
          Voeg fusie/split toe
        </Button>
      </Box>
      <Box>
        {inputHeaders != null && mergers.map((m, i) => (
          <MergerSettings
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            mergers={mergers}
            merger={m}
            index={i}
            updateMerger={updateMerger}
            removeMerger={removeMerger}
            directMappings={directMappings}
            inputHeaders={inputHeaders}
          />
        ))}
      </Box>
    </StepCard>
  );
}

export default InputMergeSplit;
