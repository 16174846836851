'use client';

import React from 'react';
import { ErrorBoundary as ReactErrorBoundary, FallbackProps } from 'react-error-boundary';
import {
  Alert, Container, Divider,
} from '@mui/material';

function FallbackRender({ error }: FallbackProps) {
  return (
    <Container maxWidth="xl">
      <h1>Er is iets fout gegaan!</h1>
      <p>
        Er is aan de achterkant iets compleet foutgegaan en de prijslijstlader is gecrashed.
        Herlaad de pagina en probeer het opnieuw. Mocht deze foutmelding weer tevoorschijn komen,
        neem contact op. Zorg ervoor dat duidelijk beschrijft wat je aan het doen was toen je
        deze foutmelding kreeg, en dat je onderstaande foutmelding meestuurt.
      </p>
      <Alert severity="error" sx={{ whiteSpace: 'pre-wrap' }}>
        {error.message}
        <Divider sx={{ my: '1rem' }} />
        {error.stack}
      </Alert>
    </Container>
  );
}

function ErrorBoundary({ children }: React.PropsWithChildren) {
  return (
    <ReactErrorBoundary fallbackRender={FallbackRender}>
      {children}
    </ReactErrorBoundary>
  );
}

export default ErrorBoundary;
