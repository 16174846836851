import React from 'react';
import { Alert, Box, Typography } from '@mui/material';
import firebase from 'firebase/compat/app';
import Supplier from '../entities/supplier';
import { OutputRow } from '../entities/output';
import SaveOutputNewModal from './SaveOutputNewModal';
import SaveOutputExistingModal from './SaveOutputExistingModal';
import { InputListType } from '../entities/input';
import QueryDocumentSnapshot = firebase.firestore.QueryDocumentSnapshot;

interface Props {
  validationFailed: boolean;
  outputRows: OutputRow[] | null;
  supplier: Supplier;
  setSupplier: (supplier: Supplier) => void;
  selectedOutputList?: QueryDocumentSnapshot;
  inputListType?: InputListType;
}

function SaveTemplate({
  validationFailed, outputRows, supplier, setSupplier, selectedOutputList, inputListType,
}: Props) {
  const [saved, setSaved] = React.useState(false);

  React.useEffect(() => {
    setSaved(false);
  }, [outputRows, validationFailed]);

  return (
    <Box>
      <Typography variant="h6">Output</Typography>
      <Typography sx={{ fontStyle: 'italic' }}>
        Door de output van deze prijslijst op te slaan,
        kun je hem in de toekomst bij stap 11 weer hergebruiken.
        Ook wordt &ldquo;artikelnummer start&ldquo; bij stap 1 opgehoogd.
      </Typography>
      {saved && (
        <Alert severity="success" sx={{ my: 0.5 }}>
          Lijst succesvol opgeslagen.
        </Alert>
      )}
      {inputListType === InputListType.NEW || selectedOutputList === undefined ? (
        <SaveOutputNewModal
          outputRows={outputRows}
          supplier={supplier}
          setSupplier={setSupplier}
          validationFailed={validationFailed}
          saved={saved}
          setSaved={setSaved}
        />
      ) : (
        <SaveOutputExistingModal
          outputRows={outputRows}
          supplier={supplier}
          validationFailed={validationFailed}
          selectedOutputList={selectedOutputList}
          inputListType={inputListType}
          saved={saved}
          setSaved={setSaved}
        />
      )}
    </Box>
  );
}

export default SaveTemplate;
