import React from 'react';
import {
  Box, FormControl, InputLabel, MenuItem, Select, Typography,
} from '@mui/material';
import { WarningAmber } from '@mui/icons-material';
import firebase from 'firebase/compat/app';
import QueryDocumentSnapshot = firebase.firestore.QueryDocumentSnapshot;

interface Props {
  validationFailed: boolean;
  required?: boolean;
  error?: boolean;

  outputs: QueryDocumentSnapshot[] | null;
  selectedOutput: string;
  setSelectedOutput: (id: string) => void;

  disabled?: boolean;
  name?: string;
  oldListWarning?: number;
}

function OutputListSelector({
  validationFailed, required, error, outputs, selectedOutput, setSelectedOutput,
  disabled, name: nameProp, oldListWarning: oldListWarningProp,
}: Props) {
  const name = nameProp || 'Oude lijst';
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const oldListWarning = oldListWarningProp || 1;

  const menuOptions: JSX.Element[] = required ? [] : [(
    <MenuItem key="empy" value="">
      <Typography sx={{ fontStyle: 'italic' }}>Niet matchen</Typography>
    </MenuItem>
  )];
  if (outputs != null) {
    menuOptions.push(...outputs.map((o) => (
      <MenuItem key={o.id} value={o.id}>
        {`${o.get('name')} (${o.get('createdAt').toDate().toLocaleString()})`}
      </MenuItem>
    )));
  }

  const selectedTooOld = outputs && outputs.length > 0 && selectedOutput !== ''
    // && outputs[0].id !== selectedOutput;
    && outputs.slice(0, oldListWarning).every((o) => o.id !== selectedOutput);

  return (
    <Box>
      <FormControl
        fullWidth
        margin="none"
        disabled={validationFailed || disabled}
        required={required}
        error={(required && selectedOutput === '') || error}
      >
        <InputLabel id="select-previous-list-label">
          {name}
          {!required && ' (optioneel)'}
        </InputLabel>
        <Select
          labelId="select-previous-list-label"
          id="select-previous-list"
          label={`${name}${!required && ' (optioneel)'}`}
          value={selectedOutput}
          onChange={(event) => setSelectedOutput(event.target.value)}
        >
          {menuOptions}
        </Select>
      </FormControl>
      {selectedTooOld && (
        <Box sx={(theme) => ({
          display: 'flex',
          color: theme.palette.warning.main,
          my: '0.5rem',
          columnGap: '0.25rem',
        })}
        >
          <WarningAmber color="warning" />
          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
            Je hebt niet de nieuwste lijst gekozen! Weet je dit zeker?
            Het kan zijn dat je nu producten overslaat en dus dubbele nummers gaat geven!
          </Typography>
        </Box>
      )}
    </Box>
  );
}

export default OutputListSelector;
