import { Box, Button } from '@mui/material';
import React from 'react';
import { RemoveCircleOutline, Replay } from '@mui/icons-material';
import ConverterView from './ConverterView';
import ManagementView from './mgmt/ManagementView';

function ConverterViewWrapper() {
  const [key, setKey] = React.useState(0);
  const [adminOpen, setAdminOpen] = React.useState(false);

  return (
    <Box sx={{ marginTop: '2rem' }}>
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: '0.5rem',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      >
        <Button
          variant="contained"
          onClick={() => setKey(key + 1)}
          sx={{ backgroundColor: 'grey' }}
          startIcon={<Replay />}
        >
          Reset formulier
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={() => setAdminOpen(true)}
          startIcon={<RemoveCircleOutline />}
        >
          Danger zone
        </Button>
        <ManagementView open={adminOpen} setOpen={setAdminOpen} />
      </Box>
      <ConverterView key={key} />
    </Box>
  );
}

export default ConverterViewWrapper;
