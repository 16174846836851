import React from 'react';
import { Box, TableCell, TableRow } from '@mui/material';
import { Abc, Numbers } from '@mui/icons-material';
import OutputOrMergerSelect from './OutputOrMergerSelect';
import { ColumnType, InputColumnHeader } from '../../entities/input';
import { Merger } from '../../entities/mappers';

interface Props {
  column: InputColumnHeader;
  index: number;
  mergers: Merger[];
  value: string[];
  changeValue: (input: InputColumnHeader, outputString: string | string[]) => void;
  duplicates: string[];
}

function InputMappingRow({
  column, index, mergers, value, changeValue, duplicates,
}: Props) {
  let icon: JSX.Element;
  let title: string;
  switch (column.type) {
    case ColumnType.STRING:
      icon = <Abc fontSize="small" />;
      title = 'Deze kolom bevat tekst';
      break;
    case ColumnType.NUMBER:
      icon = <Numbers fontSize="small" />;
      title = 'Deze kolom bevat getallen';
      break;
    default:
      throw new Error(`Unknown ColumnType: '${column.type}'`);
  }

  return (
    <TableRow key={column.key}>
      <TableCell>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.25rem' }} title={title}>
          {icon}
          {column.name}
        </Box>
      </TableCell>
      <TableCell>
        <OutputOrMergerSelect
          mergers={mergers}
          index={index}
          input={column}
          value={value}
          changeValue={changeValue}
          duplicates={duplicates}
        />
      </TableCell>
    </TableRow>
  );
}

export default InputMappingRow;
