import React from 'react';
import {
  Box,
  Dialog, DialogContent, DialogTitle, Divider, Tab, Tabs, Typography,
} from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';
import MgmtAddSupplier from './MgmtAddSupplier';
import MgmtSavedOutputs from './MgmtSavedOutputs';
import MgmtUpdateSupplier from './MgmtUpdateSupplier';
import MgmtDownloadSavedList from './MgmtDownloadSavedList';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
}

function ManagementView({ open, setOpen }: Props) {
  const [tab, setTab] = React.useState('1');

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle>
        DANGER ZONE --- Doe hier alleen dingen als je weet wat je aan het doen bent!
      </DialogTitle>
      <DialogContent>
        <Typography sx={{ fontStyle: 'italic' }}>
          Indien er om wat voor reden data uit de prijslijstlader gehaald moet worden,
          neem contact op met Roy Kakkenberg.
        </Typography>
        <Divider sx={{ my: 2 }} />
        <TabContext value={tab}>
          <Box>
            <Tabs
              value={tab}
              onChange={(event, newValue) => setTab(newValue)}
              centered
            >
              <Tab label="Leveranciers aanpassen" value="1" />
              <Tab label="Leveranciers toevoegen" value="2" />
              <Tab label="Exports handmatig toevoegen" value="3" />
              <Tab label="Exports downloaden" value="4" />
            </Tabs>
          </Box>
          <TabPanel value="1">
            <MgmtUpdateSupplier />
          </TabPanel>
          <TabPanel value="2">
            <MgmtAddSupplier />
          </TabPanel>
          <TabPanel value="3">
            <MgmtSavedOutputs />
          </TabPanel>
          <TabPanel value="4">
            <MgmtDownloadSavedList />
          </TabPanel>
        </TabContext>
      </DialogContent>
    </Dialog>
  );
}

export default ManagementView;
