import React from 'react';
import {
  Card, CardActions, CardContent, CardHeader, CardProps, Typography,
} from '@mui/material';
import Box from '@mui/material/Box';

interface Props extends CardProps {
  step: number | string
  title: string
  children: React.ReactNode | React.ReactNode[]
  actions?: React.ReactNode | React.ReactNode[]
}

function StepCard({
  step, title, children, actions, ...rest
}: Props): JSX.Element {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Card {...rest}>
      <CardHeader
        avatar={(
          <Box sx={(theme) => ({
            width: '4rem',
            height: '4rem',
            backgroundColor: theme.palette.primary.main,
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: theme.palette.primary.contrastText,
          })}
          >
            <Typography variant="h4" sx={{ fontWeight: 'bold' }}>{step}</Typography>
          </Box>
        )}
        title={<Typography variant="h4" sx={{ }}>{title}</Typography>}
      />
      <CardContent>
        {children}
      </CardContent>
      {(actions != null) && (
        <CardActions sx={{ justifyContent: 'right' }}>
          {actions}
        </CardActions>
      )}
    </Card>
  );
}

export default StepCard;
