export function isInteger(value: string): boolean {
  const num = parseInt(value, 10);
  return !Number.isNaN(num) && num.toString() === value;
}

/**
 * Get all indices of a substring in a given string
 * @param value
 * @param searchString
 */
function findIndices(value: string, searchString: string): number[] {
  let val = `${value}`;
  let i = 0;
  const result: number[] = [];

  // eslint-disable-next-line no-constant-condition
  while (true) {
    const index = val.indexOf(searchString);
    if (index === -1) break;

    result.push(index + i);
    val = val.replace(searchString, '');
    i += searchString.length;
  }
  return result;
}

/**
 * Parse a number in any international format to a Javascript number
 * @param value
 */
export function parseNumber(value: any): number {
  if (typeof value === 'number') return value;
  if (typeof value !== 'string') return NaN;

  let temp = value;
  const commaIndices = findIndices(value, ',');
  const dotIndices = findIndices(value, '.');

  if (dotIndices.length === 0 && commaIndices.length === 0) {
    return parseInt(temp, 10);
  }
  if (dotIndices.length === 1 && commaIndices.length === 0) {
    // 1234.67
  } else if (dotIndices.length === 0 && commaIndices.length === 1) {
    // 1234,67
    temp = temp.replaceAll(',', '.');
  } else if (dotIndices.length === 1 && commaIndices.length >= 1
    && dotIndices[dotIndices.length - 1] > commaIndices[commaIndices.length - 1]) {
    // 1,234.67
    temp = temp.replaceAll(',', '');
  } else if (dotIndices.length >= 1 && commaIndices.length === 1
    && dotIndices[dotIndices.length - 1] < commaIndices[commaIndices.length - 1]) {
    // 1.234,67
    temp = temp.replaceAll('.', '')
      .replaceAll(',', '.');
  } else {
    // 1.234,567.89
    // 1,234.567.89
    // 1,2,4.5.6
    return NaN;
  }

  return parseFloat(temp);
}
