import React from 'react';
import {
  Alert, Button,
} from '@mui/material';
import StepCard from '../StepCard';
import { ValidationResult } from '../../entities/validation';
import { OutputRow } from '../../entities/output';
import getCsv from '../../workers/downloadOutput';

interface Props {
  validationResult: ValidationResult[] | null;
  outputRows: OutputRow[] | null;
}

function ExportOutput({ validationResult, outputRows }: Props) {
  const validationFailed = !validationResult || validationResult.some((v) => v.result === 'error');

  const handleDownload = () => {
    if (outputRows == null) return;
    const encodedUri = getCsv(outputRows);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'prijslijst.csv');
    document.body.appendChild(link); // Required for FF

    link.click();
    document.body.removeChild(link);
  };

  return (
    <StepCard
      step={12}
      title="Exporteren"
      sx={{ marginTop: '1.5rem' }}
      actions={(
        <Button
          variant="contained"
          disabled={validationFailed}
          onClick={handleDownload}
        >
          Exporteren
        </Button>
      )}
    >
      {validationFailed ? (
        <Alert severity="error" sx={{ marginBottom: '1rem' }}>
          Validatie op de output is nog niet succesvol.
          Los deze problemen eerst op, voordat je de lijst gaat exporteren.
        </Alert>
      ) : (
        <Alert severity="success" sx={{ marginBottom: '1rem ' }}>
          Alles is goed! Je kunt nu de prijslijst exporteren en opslaan op je computer.
          Daarna kun je hem importeren in Exact!
        </Alert>
      )}
    </StepCard>
  );
}

export default ExportOutput;
