import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';

interface Props {
  setFile: (file: File) => void
  file: File | undefined
}

function FileSelector({ setFile, file }: Props): JSX.Element {
  const handleFile = (event: any): void => {
    setFile(event.target.files[0]);
  };

  return (
    <Box>
      <Button variant="contained" color="primary" component="label">
        Kies een bestand
        <input
          type="file"
          onChange={handleFile}
          accept=".csv, .xls, .xlsx"
          hidden
        />
      </Button>
      <Box sx={{ marginTop: '1rem' }}>
        <Typography variant="body1">Gekozen bestand:</Typography>
        {(file != null)
          ? (
            <Typography variant="body1">{file.name}</Typography>
          )
          : (
            <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
              <WarningIcon color="error" sx={{ paddingTop: '-0.25rem', marginRight: '0.3rem' }} />
              <Typography variant="body1">
                Geen bestand gekozen
              </Typography>
            </Box>
          )}
      </Box>
    </Box>
  );
}

export default FileSelector;
