// eslint-disable-next-line import/prefer-default-export
export function removeEmpty(obj: any): any {
  const newObj: any = {};
  if (typeof obj === 'string') return obj;
  if (Array.isArray(obj)) return obj.map((v: any) => removeEmpty(v));

  Object.keys(obj).forEach((key) => {
    if (Array.isArray(obj[key])) newObj[key] = obj[key].map((v: any) => removeEmpty(v));
    if (typeof obj[key] === 'object') newObj[key] = removeEmpty(obj[key]);
    else if (obj[key] !== undefined) newObj[key] = obj[key];
  });

  return newObj;
}
