import React from 'react';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { Delete } from '@mui/icons-material';
import {
  DirectMapping,
  Merger,
  Splitter,
} from '../../entities/mappers';
import MergerSettingsSplitterRow from './MergerSettingsSplitterRow';
import MergerInputOrderContainer from './MergerInputOrderContainer';
import { InputColumnHeader } from '../../entities/input';
import MergerOperations from './MergerOperations';

interface Props {
  mergers: Merger[];
  merger: Merger;
  index: number;
  updateMerger: (merger: Merger, index: number) => void;
  removeMerger: (index: number) => void;
  directMappings: DirectMapping[];
  inputHeaders: InputColumnHeader[];
}

function MergerSettings({
  mergers, merger, index, updateMerger, removeMerger, directMappings, inputHeaders,
}: Props) {
  const [name, setName] = React.useState('');

  React.useEffect(() => {
    setName(merger.name);
  }, [merger]);

  const updateName = () => {
    if (name === '') {
      setName(merger.name);
      return;
    }
    updateMerger({
      ...merger,
      name,
    }, index);
  };

  const updateInputOrder = (inputIds: string[]) => {
    updateMerger({
      ...merger,
      inputIds,
    }, index);
  };

  const addOutputField = () => {
    updateMerger({
      ...merger,
      outputs: [
        ...merger.outputs,
        {
          outputColumnKeys: [],
          rawNrOfCharacters: '',
          nrOfCharacters: Number.POSITIVE_INFINITY,
        },
      ],
    }, index);
  };

  const updateSplitter = (splitter: Splitter, i: number) => {
    const outputs = [...merger.outputs];
    outputs[i] = splitter;
    updateMerger({
      ...merger,
      outputs,
    }, index);
  };

  const removeOutputField = (i: number) => {
    const outputs = [...merger.outputs];
    outputs.splice(i, 1);
    updateMerger({
      ...merger,
      outputs,
    }, index);
  };

  return (
    <Box sx={(theme) => ({
      my: '0.5rem',
      mx: '-0.5rem',
      p: '0.5rem',
      border: `2px double ${theme.palette.grey['400']}`,
      borderRadius: '0.5rem',
    })}
    >
      <TextField
        fullWidth
        label="Naam"
        required
        sx={{ marginTop: '1rem' }}
        value={name}
        error={name === ''}
        onChange={(event) => setName(event.target.value)}
        onBlur={updateName}
      />
      <Box sx={{ marginTop: '1.5rem' }}>
        <Typography variant="h5">Inputs (in volgorde)</Typography>
        {merger.inputIds.length > 0 ? (
          <MergerInputOrderContainer
            inputHeaders={inputHeaders}
            merger={merger}
            updateInputOrder={updateInputOrder}
          />
        ) : (<Typography variant="body1" sx={{ fontStyle: 'italic' }}>Er zijn nog geen inputs!</Typography>)}
      </Box>
      <MergerOperations
        merger={merger}
        index={index}
        updateMerger={updateMerger}
        inputHeaders={inputHeaders}
      />
      <Box sx={{ marginTop: '1.5rem' }}>
        <Typography variant="h5">Outputs (in volgorde)</Typography>
        <Box sx={{ padding: '0.5rem' }}>
          <Table size="small" sx={(theme) => ({ border: 1, borderColor: theme.palette.divider })}>
            <TableBody>
              {merger.outputs.map((o, i) => (
                <MergerSettingsSplitterRow
                // eslint-disable-next-line react/no-array-index-key
                  key={`${o.outputColumnKeys}-${i}`}
                  splitter={o}
                  index={i}
                  updateSplitter={updateSplitter}
                  removeSplitter={removeOutputField}
                  mergers={mergers}
                  directMappings={directMappings}
                />
              ))}
              <TableRow>
                <TableCell colSpan={3}>
                  <Button
                    variant="contained"
                    onClick={addOutputField}
                  >
                    Voeg output toe
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>
      </Box>
      <Box sx={{ my: '0.5rem', display: 'flex', justifyContent: 'right' }}>
        <Button
          variant="contained"
          color="error"
          startIcon={<Delete />}
          onClick={() => removeMerger(index)}
        >
          Verwijder fusie/split
        </Button>
      </Box>
    </Box>
  );
}

export default MergerSettings;
