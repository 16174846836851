import React from 'react';
import { OutputRow, outputColumns } from '../../entities/output';
import PreviewTable from '../PreviewTable';
import StepCard from '../StepCard';

interface Props {
  rows: OutputRow[] | null;
}

const OutputFilePreview = React.memo(({ rows }: Props) => {
  const headers = outputColumns.map((h) => h.name + (h.required ? '*' : ''));
  const rows2 = rows?.slice(0, 10)
    .map((c) => outputColumns
      .map((h) => c[h.key]));

  return (
    <StepCard step={8} title="Controleer output">
      <PreviewTable headers={headers} rows={rows2 ?? [[]]} />
    </StepCard>
  );
});

export default OutputFilePreview;
