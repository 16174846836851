import React from 'react';
import {
  FormControl, IconButton, InputLabel, MenuItem, Select, TableCell, TableRow, TextField,
} from '@mui/material';
import { Delete } from '@mui/icons-material';
import { DirectMapping, Merger, Splitter } from '../../entities/mappers';
import { isInteger } from '../../helpers/number';
import DuplicateError from './DuplicateError';
import { outputColumns } from '../../entities/output';
import getKeysDuplicates from '../../helpers/duplicates';

interface Props {
  splitter: Splitter
  index: number
  updateSplitter: (splitter: Splitter, index: number) => void
  removeSplitter: (index: number) => void
  mergers: Merger[]
  directMappings: DirectMapping[]
}

function MergerSettingsSplitterRow({
  splitter, index, updateSplitter, removeSplitter, mergers, directMappings,
}: Props) {
  const updateOutputColumn = (keys: string | string[]) => {
    const outputColumnKey = typeof keys === 'string' ? [keys] : keys;
    const outputColumns2 = outputColumnKey.map((key) => outputColumns.find((c) => c.key === key));
    if (outputColumns2.some((c) => c === undefined)) throw new Error(`Could not find output column with key ${outputColumnKey}`);
    updateSplitter({
      ...splitter,
      outputColumnKeys: outputColumns2.map((c) => c!.key),
    }, index);
  };

  const handleNrOutputCharactersChange = (value: string) => {
    const rawNrOfCharacters = value;
    updateSplitter({
      ...splitter,
      rawNrOfCharacters,
    }, index);
  };

  const updateNrOutputCharacters = () => {
    const value = splitter.rawNrOfCharacters;
    let nrOfCharacters: number;
    let rawNrOfCharacters: string;
    if (value !== '' && !isInteger(value)) {
      nrOfCharacters = 0;
      rawNrOfCharacters = '0';
    } else if (value === '') {
      nrOfCharacters = Number.POSITIVE_INFINITY;
      rawNrOfCharacters = '';
    } else {
      nrOfCharacters = parseInt(value, 10);
      rawNrOfCharacters = value;
    }
    updateSplitter({
      ...splitter,
      nrOfCharacters,
      rawNrOfCharacters,
    }, index);
  };

  const duplicates = getKeysDuplicates(splitter.outputColumnKeys, directMappings, mergers);

  return (
    <TableRow>
      <TableCell>
        <FormControl
          fullWidth
          error={splitter.outputColumnKeys.length === 0 || duplicates.length > 0}
        >
          <InputLabel id={`merger-${index}-column-${index}-label`}>Output kolom</InputLabel>
          <Select
            labelId={`merger-${index}-column-${index}-label`}
            id={`merger-${index}-column-${index}`}
            value={splitter.outputColumnKeys}
            label="Output kolom"
            onChange={(event) => updateOutputColumn(event.target.value)}
            size="small"
            multiple
          >
            {outputColumns.map((c) => (
              <MenuItem value={c.key} key={c.key}>{c.name}</MenuItem>
            ))}
          </Select>
          <DuplicateError duplicates={duplicates} />
        </FormControl>
      </TableCell>
      <TableCell>
        <TextField
          fullWidth
          margin="dense"
          label="Aantal karakters"
          value={splitter.rawNrOfCharacters}
          error={splitter.rawNrOfCharacters !== '' && !isInteger(splitter.rawNrOfCharacters)}
          onChange={(event) => handleNrOutputCharactersChange(event.target.value)}
          onBlur={updateNrOutputCharacters}
          size="small"
        />
      </TableCell>
      <TableCell>
        <IconButton onClick={() => removeSplitter(index)} title="Verwijderen">
          <Delete />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

export default MergerSettingsSplitterRow;
