import React from 'react';
import {
  Box, Table, TableBody, TableCell, TableHead, TableRow, Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import StepCard from '../StepCard';
import { DirectMapping, Merger } from '../../entities/mappers';
import { outputColumns } from '../../entities/output';
import { InputColumnHeader } from '../../entities/input';
import InputMappingRow from '../mapping/InputMappingRow';
import getKeysDuplicates from '../../helpers/duplicates';
import { aggregateOperationValid, outputOperationValid } from '../../helpers/validateParams';

interface Props {
  inputColumns: InputColumnHeader[] | null;
  hasInputRows: boolean;
  mergers: Merger[];
  setMergers: (mergers: Merger[]) => void;
  directMappings: DirectMapping[];
  setDirectMappings: (directMappings: DirectMapping[]) => void;

  handleConvert: () => void;
}

function InputMapping({
  inputColumns, hasInputRows, mergers: columnMergers, setMergers,
  directMappings, setDirectMappings, handleConvert,
}: Props): JSX.Element {
  const [loading, setLoading] = React.useState(false);

  const addSelectionMapping = (
    input: InputColumnHeader,
    outputKeys: string | string[],
    newMapping: DirectMapping,
  ): void => {
    let keys = typeof outputKeys === 'string' ? [outputKeys] : outputKeys;
    keys = keys.map((key) => key.substring(2));

    // Add the new mapping
    const outputs = keys.map((key) => outputColumns.find((c) => c.key === key));
    if (outputs.some((o) => o === undefined)) throw new Error('Unknown output column');

    newMapping.outputKeys.push(...keys);
  };

  const updateSelectionMerger = (
    input: InputColumnHeader,
    outputKey: string,
    mergers: Merger[],
  ): Merger[] => {
    const mergersCopy = [...mergers];
    const index = mergersCopy.findIndex((m) => m.key === outputKey.substring(2));
    mergersCopy[index].inputIds.push(input.id);
    return mergersCopy;
  };

  const updateSelection = (
    input: InputColumnHeader,
    outputString: string | string[],
  ) => {
    let mergers = [...columnMergers];
    const mappings = [...directMappings];

    // Get the IDs
    let outputKeys: string[];
    if (typeof outputString === 'string') {
      outputKeys = [outputString];
    } else {
      outputKeys = outputString;
    }

    // Check for existing direct mappings
    const mappingToRemoveIndex = mappings.findIndex((m) => m.inputId === input.id);
    if (mappingToRemoveIndex >= 0) {
      mappings.splice(mappingToRemoveIndex, 1);
    }

    // Check for existing mergers
    const mergersToRemove = mergers.filter((m) => m.inputIds.some((id2) => id2 === input.id));
    mergersToRemove.forEach((m1) => {
      const i2 = mergers.findIndex((m2) => JSON.stringify(m1) === JSON.stringify(m2));
      const inputIds = [...mergers[i2].inputIds];
      const j = inputIds.findIndex((id2) => id2 === input.id);
      inputIds.splice(j, 1);
      mergers[i2].inputIds = inputIds;
    });

    const newMapping: DirectMapping = {
      inputId: input.id,
      outputKeys: [],
    };

    outputKeys.forEach((id) => {
      if (id.substring(0, 1) === 'c') {
        addSelectionMapping(input, id, newMapping);
      } else if (id.substring(0, 1) === 'm') {
        mergers = updateSelectionMerger(input, id, mergers);
      } else {
        console.error('Unknown entity type', id);
      }
    });

    if (newMapping.outputKeys.length > 0) mappings.push(newMapping);

    setDirectMappings(mappings);
    setMergers(mergers);
  };

  const getSelectionValue = (column: InputColumnHeader): string[] => {
    // Check if this input column is in a mapping
    const mapping = directMappings.find((m) => m.inputId === column.id);
    // Check if this input column is in one of the merger inputs
    const mergers = columnMergers.filter((m) => m.inputIds.some((id) => id === column.id));

    const values: string[] = [];
    if (mapping) {
      values.push(...mapping.outputKeys.map((key) => `c-${key}`));
    }
    if (mergers.length > 0) {
      values.push(...mergers.map((merger) => `m-${merger.key}`));
    }
    return values;
  };

  const getDuplicates = (inputColumn: InputColumnHeader): string[] => {
    const outputColumn = directMappings.find((m) => m.inputId === inputColumn.id);
    if (outputColumn) {
      return getKeysDuplicates(outputColumn.outputKeys, directMappings, columnMergers);
    }
    return [];
  };

  const mergersValid = inputColumns && columnMergers
    .every((m) => aggregateOperationValid(m, inputColumns) && outputOperationValid(m));

  return (
    <StepCard
      step="7a"
      title="Input toewijzen"
      actions={(
        <LoadingButton
          variant="contained"
          onClick={() => {
            setLoading(true);
            handleConvert();
            setLoading(false);
          }}
          loading={loading}
          disabled={!hasInputRows || !mergersValid}
        >
          Converteer
        </LoadingButton>
    )}
    >
      <Box sx={{ marginBottom: '1rem' }}>
        <Typography variant="body1">
          Bij het converteren wordt de inputkolom gekopieerd naar de output kolom.
          Je kunt ook een fusie/split blok kiezen. Deze staan onderaan de lijst.
          Voor uitleg over deze blokken, ga naar stap 6b.
        </Typography>
      </Box>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Input kolom</TableCell>
            <TableCell>Output kolom</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {inputColumns && inputColumns.map((column, i) => (
            <InputMappingRow
              key={column.key}
              column={column}
              index={i}
              mergers={columnMergers}
              value={getSelectionValue(column)}
              changeValue={updateSelection}
              duplicates={getDuplicates(column)}
            />
          ))}
        </TableBody>
      </Table>
    </StepCard>
  );
}

export default InputMapping;
