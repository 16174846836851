import { outputColumns, OutputRow } from '../entities/output';

export interface RemoveDuplicatesResponse {
  newOutput: OutputRow[],
  nrOfRemovedRows: number,
}

export default function removeDuplicates(output: OutputRow[]): RemoveDuplicatesResponse {
  const newOutput = output.filter((row1, i) => {
    // Get the index of a row that has all the same values
    const index = output.findIndex((row2) => outputColumns
      .every((c) => row1[c.key] === row2[c.key]));
    // If this index is not the index of the current item,
    // this means that there exists a second copy of this row!
    // Therefore, keep only the first copy of every row
    return index === i;
  });

  return {
    newOutput,
    nrOfRemovedRows: output.length - newOutput.length,
  };
}
