import React from 'react';
import {
  Box, Dialog, DialogContent, DialogTitle, Typography,
} from '@mui/material';
import PreviewTable from './PreviewTable';
import { outputColumns, OutputRow } from '../entities/output';

interface Props {
  rows: OutputRow[];
  open: boolean;
  handleClose: () => void;
}

function MatchingErrorDetails({ rows: allRows, open, handleClose }: Props) {
  const [headers] = React.useState<string[]>(
    outputColumns.map((c) => c.name),
  );

  const rows = allRows.slice(0, 10)
    .map((r) => outputColumns.map((c) => r[c.key]));

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xl">
      <DialogTitle>Artikelnummers toewijzen gefaald</DialogTitle>
      <DialogContent>
        <Typography variant="body1">Artikelnummers toewijzen is mislukt! De volgende rijen bestaan al in de originele lijst. Deze zijn dus waarschijnlijk dubbel.</Typography>

        <Box>
          <PreviewTable headers={headers} rows={rows} />
          <Typography variant="body1" sx={{ marginTop: '0.5rem' }}>
            ...en
            {' '}
            {allRows.length - rows.length}
            {' '}
            regels meer.
          </Typography>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default MatchingErrorDetails;
