import type { Identifier, XYCoord } from 'dnd-core';
import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { Box } from '@mui/material';
import { Abc, Numbers } from '@mui/icons-material';
import { ColumnType, InputColumnHeader } from '../../entities/input';

const style = {
  border: '1px dashed gray',
  padding: '0.5rem 1rem',
  marginBottom: '.5rem',
  cursor: 'move',
};

export interface CardProps {
  inputHeaders: InputColumnHeader[]
  index: number;
  inputId: string;
  moveInput: (dragIndex: number, hoverIndex: number) => void
  handleDrop: () => void;
}

interface DragItem extends InputColumnHeader {
  index: number;
}

function MergerInputOrderCard({
  inputHeaders, index, inputId, moveInput, handleDrop,
}: CardProps) {
  const ref = useRef<HTMLDivElement>(null);
  const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: 'card',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveInput(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      // eslint-disable-next-line no-param-reassign
      item.index = hoverIndex;
    },
    drop: handleDrop,
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'card',
    item: () => ({ id: inputId, index }),
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  const input = inputHeaders.find((h) => h.id === inputId);
  if (input == null) {
    throw new Error(`No input header with ID ${inputId}`);
  }

  const getIcon = () => {
    switch (input.type) {
      case ColumnType.NUMBER:
        return (<Numbers fontSize="small" />);
      case ColumnType.STRING:
        return (<Abc fontSize="small" />);
      default:
        throw new Error(`Unknown column type for column ${input.name}: ${input.type}`);
    }
  };

  return (
    <Box ref={ref} style={{ ...style, opacity }} data-handler-id={handlerId}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.25rem' }}>
        {getIcon()}
        {input.name}
      </Box>
    </Box>
  );
}

export default MergerInputOrderCard;
