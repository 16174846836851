import React from 'react';
import { Grid } from '@mui/material';
import Supplier from '../entities/supplier';
import { OutputRow } from '../entities/output';
import { InputRow, InputColumnHeader } from '../entities/input';
import Template, { TemplateOptions } from '../entities/template';
import InputSelectPhase from './phases/InputSelectPhase';
import InputMappingPhase from './phases/InputMappingPhase';
import OutputPhase from './phases/OutputPhase';
import InputFilePreview from '../components/steps/InputFilePreview';
import OutputFilePreview from '../components/steps/OutputFilePreview';
// import computationInThreads from '../helpers/threads';
// import { splitIntoChunks } from '../helpers/array';

function ConverterView(): JSX.Element {
  const [supplier, setSupplier] = React.useState<Supplier>({
    id: '',
    name: '',
    code: '',
    itemCodeTemplate: '',
  });

  const [template, setTemplate] = React.useState<Template | null>(null);
  const [newTemplateOptions, setNewTemplateOptions] = React.useState<TemplateOptions | null>(null);

  const [parsedInputFile, setParsedInputFile] = React.useState<InputRow[] | null>(null);
  const [parsedInputFileHeaders, setParsedInputFileHeaders] = React
    .useState<InputColumnHeader[] | null>(null);

  const [parsedOutputFile, setParsedOutputFile] = React.useState<OutputRow[] | null>(null);

  const xs = 12;
  const md = 6;
  const lg = 4;

  const inputPreviewTable = React.useMemo(() => (
    <InputFilePreview input={parsedInputFile} headers={parsedInputFileHeaders} />
  ), [parsedInputFile, parsedInputFileHeaders]);

  const outputPreviewTable = React.useMemo(() => (
    <OutputFilePreview rows={parsedOutputFile} />
  ), [parsedOutputFile]);

  return (
    <Grid container columnSpacing={3} rowSpacing={3} sx={{ my: '2rem' }}>
      <InputSelectPhase
        xs={xs}
        md={md}
        lg={lg}
        supplier={supplier}
        setSupplier={setSupplier}
        setParsedInputFile={setParsedInputFile}
        setParsedInputFileHeaders={setParsedInputFileHeaders}
      />

      <Grid item xs={12} md={12} lg={12}>
        {inputPreviewTable}
      </Grid>

      <InputMappingPhase
        xs={xs}
        md={md}
        lg={lg}
        supplier={supplier}
        parsedInputFile={parsedInputFile}
        parsedInputFileHeaders={parsedInputFileHeaders}
        setParsedInputFileHeaders={setParsedInputFileHeaders}
        template={template}
        setTemplate={setTemplate}
        setNewTemplateOptions={setNewTemplateOptions}
        setParsedOutputFile={setParsedOutputFile}
      />

      <Grid item xs={12} md={12} lg={12}>
        {outputPreviewTable}
      </Grid>

      <OutputPhase
        xs={xs}
        md={md}
        lg={lg}
        supplier={supplier}
        setSupplier={setSupplier}
        template={template}
        newTemplateOptions={newTemplateOptions}
        parsedOutputFile={parsedOutputFile}
        setParsedOutputFile={setParsedOutputFile}
      />
    </Grid>
  );
}

export default ConverterView;
