import React from 'react';
import { Box, CircularProgress } from '@mui/material';

function LoadingView() {
  return (
    <Box sx={{
      display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh',
    }}
    >
      <CircularProgress />
    </Box>
  );
}

export default LoadingView;
