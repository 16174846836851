import React from 'react';
import { Alert, Box, Collapse } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import SelectSupplierForm from '../../components/SelectSupplierForm';
import Supplier from '../../entities/supplier';
import { supplierValid } from '../../helpers/validateParams';
import FirebaseContext from '../../firebaseContext';

function MgmtAddSupplier() {
  const { db } = React.useContext(FirebaseContext);

  const getInitialSupplier = (): Supplier => ({
    id: '-1',
    name: '',
    code: '',
    itemCodeTemplate: '',
  });

  const [loading, setLoading] = React.useState(false);
  const [supplier, setSupplier2] = React.useState<Supplier>(getInitialSupplier);
  const [showSuccess, setShowSuccess] = React.useState(false);

  const setSupplier = (sup: Supplier, suc = false) => {
    setSupplier2(sup);
    setShowSuccess(suc);
  };

  const saveSupplier = async () => {
    if (!supplierValid(supplier)) return;
    setLoading(true);

    try {
      await db.collection('suppliers').add({
        name: supplier.name,
        code: supplier.code,
        itemCodeTemplate: supplier.itemCodeTemplate,
      });
      setSupplier(getInitialSupplier(), true);
    } catch (e) {
      console.error('Could not save new supplier.', e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      <Collapse in={showSuccess}>
        <Alert severity="success">
          Leverancier successvol opgeslagen!
        </Alert>
      </Collapse>
      <SelectSupplierForm
        enabled
        noSelectExisting
        supplier={supplier}
        setSupplier={setSupplier}
      />
      <LoadingButton
        sx={{ mt: 1 }}
        variant="contained"
        disabled={!supplierValid(supplier)}
        loading={loading}
        onClick={saveSupplier}
      >
        Toevoegen
      </LoadingButton>
    </Box>
  );
}

export default MgmtAddSupplier;
