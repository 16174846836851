import { DirectMapping, Merger } from '../entities/mappers';
import { outputColumns } from '../entities/output';

export function hasOutputDuplicates(
  outputKey: string,
  mappings: DirectMapping[],
  mergers: Merger[],
) {
  const includedMappings = mappings
    .filter((m) => m.outputKeys.includes(outputKey));
  const includedMergers = mergers
    .filter((m) => m.outputs.some((o) => o.outputColumnKeys.includes(outputKey)));
  return includedMappings.length + includedMergers.length > 1;
}

export default function getKeysDuplicates(
  keys: string[],
  mappings: DirectMapping[],
  mergers: Merger[],
): string[] {
  const duplicateKeys = keys
    .filter((outputKey) => hasOutputDuplicates(outputKey, mappings, mergers));
  return duplicateKeys.map((key) => outputColumns.find((c) => c.key === key)?.name || '???');
}
