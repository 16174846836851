import React from 'react';
import {
  Box, styled, Table, TableBody, TableCell, TableFooter, TableHead, TableRow,
} from '@mui/material';

const OutlinedTableCell = styled(TableCell)(({ theme }) => ({
  borderLeft: `2px solid ${theme.palette.error.main}`,
  borderRight: `2px solid ${theme.palette.error.main}`,
}));

interface Props {
  headers: (string | number)[];
  rows: (string | number | undefined)[][];
  outlinedColumns?: number[] | undefined;
}

const addEllipsis = (content: any) => (
  <Box
    sx={{
      WebkitLineClamp: 7,
      WebkitBoxOrient: 'vertical',
      display: '-webkit-box',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }}
    title={content}
  >
    {content}
  </Box>
);

function PreviewTable({ headers, rows, outlinedColumns }: Props) {
  return (
    <Box sx={{ marginTop: '1rem', overflowX: 'scroll' }}>
      <Table size="small">
        <TableHead>
          <TableRow>
            {headers.map((h, i) => ((outlinedColumns?.includes(i)) ? (
            // eslint-disable-next-line react/no-array-index-key
              <OutlinedTableCell key={`${h}-${i}`} sx={(theme) => ({ borderTop: `2px solid ${theme.palette.error.main}` })}>
                {addEllipsis(h)}
              </OutlinedTableCell>
            )
              // eslint-disable-next-line react/no-array-index-key
              : (<TableCell key={`${h}-${i}`}>{addEllipsis(h)}</TableCell>)))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            // eslint-disable-next-line react/no-array-index-key
            <TableRow>
              {row.map((x, j) => ((outlinedColumns?.includes(j)) ? (
              // eslint-disable-next-line react/no-array-index-key
                <OutlinedTableCell key={`${x}-${j}`}>{addEllipsis(x)}</OutlinedTableCell>
              ) : (
              // eslint-disable-next-line react/no-array-index-key
                <TableCell key={`${x}-${j}`}>{addEllipsis(x)}</TableCell>
              )))}
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell colSpan={headers.length} />
          </TableRow>
        </TableFooter>
      </Table>
    </Box>
  );
}

export default PreviewTable;
