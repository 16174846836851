import React from 'react';
import { Grid } from '@mui/material';
import TemplateSelector from '../../components/steps/TemplateSelector';
import DefaultColumns from '../../components/steps/DefaultColumns';
import InputMapping from '../../components/steps/InputMapping';
import InputMergeSplit from '../../components/steps/InputMergeSplit';
import Supplier from '../../entities/supplier';
import Template, { TemplateOptions } from '../../entities/template';
import { InputRow, InputColumnHeader } from '../../entities/input';
import { OutputRow } from '../../entities/output';
import { DirectMapping, Merger } from '../../entities/mappers';
import { ConvertParameters } from '../../workers/convertInputToOutput';

interface Props {
  xs: number;
  md: number;
  lg: number;

  supplier: Supplier;
  parsedInputFile: InputRow[] | null;
  parsedInputFileHeaders: InputColumnHeader[] | null;
  setParsedInputFileHeaders: (headers: InputColumnHeader[] | null) => void;

  template: Template | null;
  setTemplate: (template: Template) => void;
  setNewTemplateOptions: (template: TemplateOptions) => void;

  setParsedOutputFile: (rows: OutputRow[] | null) => void;
}

function InputMappingPhase({
  xs, md, lg,
  supplier, parsedInputFile, parsedInputFileHeaders, setParsedInputFileHeaders,
  template, setTemplate, setNewTemplateOptions, setParsedOutputFile,
}: Props) {
  const [defaultOutputColumns, setDefaultOutputColumns] = React.useState<OutputRow | null>(null);
  const [mergers, setMergers] = React.useState<Merger[]>([]);
  const [directMappings, setDirectMappings] = React.useState<DirectMapping[]>([]);

  const handleTemplateChange = (t: Template, matchedInputColumns?: InputColumnHeader[]) => {
    setTemplate(t);
    setDefaultOutputColumns(t.options.defaultOutputColumns);
    setMergers(t.options.mergers);
    setDirectMappings(t.options.mappings);
    if (!matchedInputColumns) return;

    if (!parsedInputFileHeaders || parsedInputFileHeaders.length !== matchedInputColumns.length) {
      throw new Error('Cannot overwrite input file headers, because they are not of the same length');
    }
    setParsedInputFileHeaders(matchedInputColumns);
  };

  React.useEffect(() => {
    handleTemplateChange({
      id: '-1',
      name: '',
      options: {
        defaultOutputColumns: {},
        mergers: [],
        mappings: [],
        inputColumns: [],
      },
      createdAt: new Date(),
    });
  }, [parsedInputFile]);

  const handleOutput = (): void => {
    if (!parsedInputFile || !parsedInputFileHeaders || !defaultOutputColumns) return;

    // TODO: use multiple threads to convert the input to increase performance
    // const output = convertInputToOutput(parsedInputFile, mergers, directMappings);
    // computationInThreads<ConvertParameters, OutputColumn>(
    //   new URL('../workers/convertInputToOutput.ts', import.meta.url),
    //   (size: number) => splitIntoChunks
    //   (parsedInputFile, size).map((chunk): ConvertParameters => ({
    //     inputTable: chunk,
    //     mergers,
    //     directMappings,
    //   })),
    // )
    //   .then((result: OutputColumn[]) => setParsedOutputFile(result))
    //   .catch((err) => console.error(err));

    const worker = new Worker(new URL('../../workers/convertInputToOutput.ts', import.meta.url));
    worker.onmessage = (ev: MessageEvent<OutputRow[]>) => {
      setParsedOutputFile(ev.data);
      setNewTemplateOptions({
        inputColumns: parsedInputFileHeaders,
        defaultOutputColumns,
        mergers,
        mappings: directMappings,
      });
      worker.terminate();
    };
    worker.onerror = (error) => {
      console.error(error);
      worker.terminate();
    };

    const workerOptions: ConvertParameters = {
      inputTable: parsedInputFile,
      mergers,
      directMappings,
      defaults: defaultOutputColumns,
      supplierCode: supplier.code,
      inputHeaders: parsedInputFileHeaders,
    };

    worker.postMessage(workerOptions);
  };

  return (
    <Grid item xs={12} container columnSpacing={3} rowSpacing={3}>

      <Grid item xs={xs} md={md} lg={lg}>
        <TemplateSelector
          supplier={supplier}
          template={template}
          setTemplate={handleTemplateChange}
          inputFileHeaders={parsedInputFileHeaders}
          hasInputRows={parsedInputFile != null && parsedInputFile.length > 0}
        />
        <DefaultColumns
          defaults={defaultOutputColumns}
          setDefaults={setDefaultOutputColumns}
        />
      </Grid>

      <Grid item xs={xs} md={md} lg={lg}>
        <InputMapping
          inputColumns={parsedInputFileHeaders}
          hasInputRows={parsedInputFile != null && parsedInputFile.length > 0}
          mergers={mergers}
          setMergers={setMergers}
          directMappings={directMappings}
          setDirectMappings={setDirectMappings}
          handleConvert={handleOutput}
        />
      </Grid>

      <Grid item xs={xs} md={md} lg={lg}>
        <InputMergeSplit
          mergers={mergers}
          setMergers={setMergers}
          directMappings={directMappings}
          inputHeaders={parsedInputFileHeaders}
        />
      </Grid>
    </Grid>
  );
}

export default InputMappingPhase;
