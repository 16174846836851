import firebase from 'firebase/compat/app';
import { CHUNK_SIZE, codeColumnKey, OutputRow } from '../entities/output';
import Supplier from '../entities/supplier';
import { parseItemCodeTemplate } from '../workers/assignItemCodes';
import QueryDocumentSnapshot = firebase.firestore.QueryDocumentSnapshot;

export function calculateNewTemplate(outputRows: OutputRow[], supplier: Supplier): string {
  const highest = outputRows.map((r) => r[codeColumnKey]).sort((a, b) => {
    if (a! < b!) return 1;
    if (a! > b!) return -1;
    return 0;
  })[0];

  const {
    prefix: outputPrefix, digits: outputDigits, suffix: outputSuffix,
  } = parseItemCodeTemplate(highest as string);
  const { digits: templateDigits } = parseItemCodeTemplate(supplier.itemCodeTemplate);
  const digitsLength = Math.max(outputDigits.length, templateDigits.length);
  // The new template should be the same as the old one if we did not assign new item codes
  const digitsNew = Math.max(parseInt(outputDigits, 10) + 1, parseInt(templateDigits, 10));
  return `${outputPrefix}${(digitsNew).toString().padStart(digitsLength, '0')}${outputSuffix}`;
}

export function fetchOutputs(
  supplier: Supplier,
  setOutputs: (outputs: QueryDocumentSnapshot[] | null) => void,
): () => void {
  return () => {
    if (!supplier || supplier.id === '') {
      setOutputs(null);
      return;
    } if (supplier.id === '-1') {
      setOutputs([]);
      return;
    }

    const supplierRef = firebase.firestore().doc(`suppliers/${supplier.id}`);

    supplierRef.collection('outputs')
      .orderBy('createdAt', 'desc')
      .get()
      .then((querySnapshot) => {
        setOutputs(querySnapshot.docs);
      })
      .catch((err) => {
        console.error('Failed to execute query', err);
      });
  };
}

/**
 * Add a list of output rows to an existing output list
 * @param output
 * @param outputRows
 */
export async function addOutputsToExistingList(
  output: QueryDocumentSnapshot,
  outputRows: OutputRow[],
) {
  // Store rows in database
  const nrChunks = Math.ceil(outputRows.length / CHUNK_SIZE);
  const chunks: OutputRow[][] = [];
  for (let i = 0; i < nrChunks; i += 1) {
    chunks.push(outputRows.slice(i * CHUNK_SIZE, (i + 1) * CHUNK_SIZE));
  }

  const recordsRef = output.ref.collection('records');
  for (let i = 0; i < chunks.length; i += 1) {
    const chunk = chunks[i];
    // eslint-disable-next-line no-await-in-loop
    await recordsRef.add({ chunk: JSON.stringify(chunk) });
  }
}

export function rowsMatch(row1: OutputRow, row2: OutputRow, key: string): boolean {
  return row1[key] === row2[key];
}
