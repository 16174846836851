import React from 'react';
import {
  Box, Dialog, DialogContent, DialogTitle, IconButton, Typography,
} from '@mui/material';
import { ExpandCircleDown } from '@mui/icons-material';
import PreviewTable from './PreviewTable';
import { outputColumns, OutputColumnTemplate } from '../entities/output';
import { getErrorMessage, ValidationError } from '../entities/validation';

interface Props {
  error: ValidationError;
  column: OutputColumnTemplate;
}

function ValidationErrorDetails({ error, column }: Props) {
  const [open, setOpen] = React.useState(false);
  const [headers] = React.useState<string[]>(
    outputColumns.map((c) => c.name),
  );

  const handleClose = () => {
    setOpen(false);
  };

  const rows = error.failingRows.slice(0, 10)
    .map((r) => outputColumns.map((c) => r[c.key]));
  let outlinedColumns: number[] | undefined;
  const outlinedColumn: number | undefined = outputColumns.findIndex((c) => c.key === column.key);
  if (outlinedColumn === -1) {
    outlinedColumns = undefined;
  } else {
    outlinedColumns = [outlinedColumn, ...outputColumns[outlinedColumn]
      .uniqueWithKeys?.map((key) => outputColumns
        .findIndex((c) => c.key === key))
      .filter((i) => i !== -1) || []];
  }

  return (
    <>
      <IconButton onClick={() => setOpen(true)}>
        <ExpandCircleDown fontSize="small" />
      </IconButton>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xl">
        <DialogTitle>Validatie gefaald</DialogTitle>
        <DialogContent>
          <Typography variant="body1">De volgende rijen hebben de validatie gefaald om de volgende reden:</Typography>
          <Typography variant="body1" sx={{ marginTop: '1rem', fontStyle: 'italic' }}>{getErrorMessage(error)}</Typography>

          <Box>
            <PreviewTable headers={headers} rows={rows} outlinedColumns={outlinedColumns} />
            <Typography variant="body1" sx={{ marginTop: '0.5rem' }}>
              ...en
              {' '}
              {error.failingRows.length - rows.length}
              {' '}
              regels meer.
            </Typography>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default ValidationErrorDetails;
