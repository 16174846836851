import React from 'react';
import {
  Divider, Grid, Typography,
} from '@mui/material';
import firebase from 'firebase/compat/app';
import StepCard from '../StepCard';
import Supplier from '../../entities/supplier';
import { OutputRow } from '../../entities/output';
import Template, { TemplateOptions } from '../../entities/template';
import SaveTemplate from '../SaveTemplate';
import SaveOutput from '../SaveOutput';
import QueryDocumentSnapshot = firebase.firestore.QueryDocumentSnapshot;
import { InputListType } from '../../entities/input';

interface Props {
  validationFailed: boolean;
  outputRows: OutputRow[] | null;
  supplier: Supplier;
  setSupplier: (supplier: Supplier) => void;
  oldTemplate: Template | null;
  newTemplateOptions: TemplateOptions | null;
  selectedOutputList?: QueryDocumentSnapshot;
  inputListType?: InputListType,
}

function SaveWork({
  validationFailed, outputRows, supplier, setSupplier,
  oldTemplate, newTemplateOptions, selectedOutputList, inputListType,
}: Props) {
  return React.useMemo(() => (
    <StepCard
      step={13}
      title="Opslaan"
      sx={{ marginTop: '1.5rem' }}
    >
      <Typography>
        Is de lijst correct en is het importeren in Exact gelukt? Dan kun je hier je werk opslaan!
      </Typography>

      <Divider sx={{ my: '1.5rem' }} />

      <Grid container spacing={6}>
        <Grid item xs={12} md={6}>
          <SaveTemplate
            validationFailed={validationFailed}
            oldTemplate={oldTemplate}
            newTemplateOptions={newTemplateOptions}
            supplier={supplier}
            setSupplier={setSupplier}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SaveOutput
            validationFailed={validationFailed}
            outputRows={outputRows}
            supplier={supplier}
            setSupplier={setSupplier}
            selectedOutputList={selectedOutputList}
            inputListType={inputListType}
          />
        </Grid>
      </Grid>
    </StepCard>
  ), [validationFailed, outputRows, supplier, inputListType, selectedOutputList]);
}

export default SaveWork;
