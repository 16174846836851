import React from 'react';
import _ from 'lodash';
import {
  Alert,
  Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import FirebaseContext from '../firebaseContext';
import Template, { BaseTemplate, TemplateOptions } from '../entities/template';
import Supplier from '../entities/supplier';
import saveSupplierIfDoesntExist from '../workers';
import { removeEmpty } from '../helpers/object';

interface Props {
  validationFailed: boolean;
  oldTemplate: Template | null;
  newTemplateOptions: TemplateOptions | null;
  supplier: Supplier;
  setSupplier: (supplier: Supplier) => void;
}

function SaveTemplate({
  validationFailed, oldTemplate, newTemplateOptions, supplier, setSupplier,
}: Props) {
  const { db } = React.useContext(FirebaseContext);
  const [name, setName] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [hasSaved, setHasSaved] = React.useState(false);

  React.useEffect(() => {
    if (hasSaved) setHasSaved(false);
  }, [oldTemplate, newTemplateOptions]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSaveTemplate = async () => {
    if (newTemplateOptions == null) return;
    setLoading(true);
    const newTemplate: BaseTemplate = {
      createdAt: new Date(),
      name,
      // Apparently, Chrome and Firebase (aka Google) have their own opinions on how to handle
      // undefined's. Whereas LITERALLY EVERYONE ELSE simply ignores keys with value "undefined",
      // Chrome apparently handles them like normal values and Firebase denies sending them (and
      // not just ignoring them like everyone else). Therefore, with this beautiful recursive
      // function, we'll get rid of all the "undefined" keys.
      options: removeEmpty(newTemplateOptions),
    };

    console.info('Save new template: ', newTemplate);

    try {
      const supplier2 = await saveSupplierIfDoesntExist(db, supplier);
      setSupplier(supplier2);
      await db.collection('suppliers').doc(supplier2.id).collection('templates').add(newTemplate);
      setOpen(false);
      setHasSaved(true);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const templatesAreSame = _.isEqual(newTemplateOptions?.mergers, oldTemplate?.options.mergers)
    && _.isEqual(newTemplateOptions?.mappings, oldTemplate?.options.mappings)
    && _.isEqual(
      newTemplateOptions?.defaultOutputColumns,
      oldTemplate?.options.defaultOutputColumns,
    )
    && _.isEqual(
      newTemplateOptions?.inputColumns,
      oldTemplate?.options.inputColumns,
    );

  return (
    <Box>
      <Typography variant="h6">Template</Typography>
      <Typography sx={{ fontStyle: 'italic' }}>
        Door je nieuwe template op te slaan,
        kun je hem in de toekomst bij stap 5 weer hergebruiken.
      </Typography>
      {!validationFailed && templatesAreSame && (
        <Alert severity="info" sx={{ marginTop: '1rem' }}>
          Het bestaande template is niet aangepast, dus het heeft
          geen zin om deze nog een keer op te slaan.
        </Alert>
      )}
      {hasSaved && (
        <Alert severity="success" sx={{ marginTop: '1rem' }}>
          Template opgeslagen!
        </Alert>
      )}
      <Button
        variant="contained"
        disabled={validationFailed || templatesAreSame || hasSaved}
        onClick={() => setOpen(true)}
        sx={{ marginTop: '1rem' }}
      >
        Sla template op
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Sla template op</DialogTitle>
        <DialogContent>
          <TextField
            value={name}
            onChange={(event) => setName(event.target.value)}
            label="Naam (optioneel)"
            margin="dense"
          />
        </DialogContent>
        <DialogActions>
          <LoadingButton
            loading={loading}
            variant="contained"
            onClick={handleSaveTemplate}
          >
            Opslaan
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default SaveTemplate;
