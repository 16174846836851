import { OutputRow, OutputColumnTemplate, outputColumns } from '../entities/output';
import {
  ValidationError, ValidationErrorTypes, ValidationInput, ValidationResult,
} from '../entities/validation';
import { parseNumber } from '../helpers/number';

/**
 * Whether the two given values are equal.
 * If a value is of type string but contains a number, it is compared as a number
 * @param value1
 * @param value2
 */
export function twoValuesEqual(
  value1: string | number | undefined,
  value2: string | number | undefined,
): boolean {
  let v1 = value1;
  let v2 = value2;
  // If value is an integer padded with zeroes at the front, convert it to an integer
  // We cannot use isNaN, because parseInt("10ABC10") would return 10;
  if (typeof v1 === 'string' && /^0*[0-9]*$/.test(v1)) {
    v1 = parseInt(v1, 10);
  }
  if (typeof v2 === 'string' && /^0*[0-9]*$/.test(v2)) {
    v2 = parseInt(v2, 10);
  }
  return v2 === v1;
}

export function getDuplicates(uniqueKeys: string[], rows: OutputRow[]): OutputRow[] {
  const duplicateIndices: number[] = [];
  rows.forEach((r1, i) => {
    // If all keys are empty, this line is valid. Requirement is a different validation rule.
    if (uniqueKeys.every((key) => r1[key] === undefined)) return;
    // Row should not have the same value in this column as another row
    const index = rows.findIndex((r2) => uniqueKeys
      .every((key) => twoValuesEqual(r1[key], r2[key])));
    if (i !== index) {
      if (!duplicateIndices.includes(index)) {
        duplicateIndices.push(index);
      }
      duplicateIndices.push(i);
    }
  });

  return duplicateIndices
    .map((i) => rows[i])
    .sort((r1, r2) => {
      const compositeKeyR1 = uniqueKeys.map((k) => r1[k]).join('-');
      const compositeKeyR2 = uniqueKeys.map((k) => r2[k]).join('-');
      if (compositeKeyR1 < compositeKeyR2) return -1;
      if (compositeKeyR1 > compositeKeyR2) return 1;
      return 0;
    });
}

export default function validateOutput(
  headers: OutputColumnTemplate[],
  rows: OutputRow[],
): ValidationResult[] {
  if (headers === undefined || rows === undefined) return [];
  return headers.map((header) => {
    const validationErrors: ValidationError[] = [];

    if (header.required) {
      const failingRows = rows.filter((r) => (r[header.key] == null
        || r[header.key]!.toString().length === 0));
      if (failingRows.length > 0) {
        validationErrors.push({
          type: ValidationErrorTypes.EmptyValues,
          associatedColumns: [],
          failingRows,
          column: header,
        });
      }
    }

    if (header.maxLength !== undefined) {
      const failingRows = rows.filter((r) => (r[header.key] != null
        ? r[header.key]!.toString().length > header.maxLength! : false));
      if (failingRows.length > 0) {
        validationErrors.push({
          type: ValidationErrorTypes.TooLong,
          associatedColumns: [],
          failingRows,
          column: header,
        });
      }
    }

    if (header.unique) {
      const uniqueKeys: string[] = [
        header.key,
        ...header.uniqueWithKeys || []];

      const failingRows = getDuplicates(uniqueKeys, rows);

      if (failingRows.length > 0) {
        validationErrors.push({
          type: ValidationErrorTypes.NotUnique,
          associatedColumns: (header.uniqueWithKeys || [])
            .map((key) => outputColumns.find((c) => c.key === key)?.name || '???'),
          failingRows,
          column: header,
        });
      }
    }

    if (header.type === 'number') {
      const failingRows = rows
        .filter((r) => !!r[header.key])
        // @ts-ignore
        // eslint-disable-next-line no-restricted-globals
        .filter((r) => isNaN(parseNumber(r[header.key])));
      if (failingRows.length > 0) {
        validationErrors.push({
          type: ValidationErrorTypes.NotANumber,
          associatedColumns: [],
          failingRows,
          column: header,
        });
      }
    }

    if (header.type === 'boolean') {
      const failingRows = rows.filter((r) => {
        const val = r[header.key];
        const allowed = ['Ja', 'Nee', '0', 0, '1', 1];
        // If val is undefined, it is not failing for the boolean rule
        return val === undefined ? false : !allowed.includes(val);
      });
      if (failingRows.length > 0) {
        validationErrors.push({
          type: ValidationErrorTypes.NotABoolean,
          associatedColumns: [],
          failingRows,
          column: header,
        });
      }
    }

    // Return the correct validation result
    if (validationErrors.length === 0) {
      return {
        column: header,
        result: 'success',
      };
    }
    return {
      column: header,
      result: 'error',
      errors: validationErrors,
    };
  });
}

// eslint-disable-next-line no-restricted-globals
self.onmessage = (message: MessageEvent<ValidationInput>) => {
  const { headers, rows } = message.data;
  const result = validateOutput(headers, rows);
  postMessage(result);
};
