import React from 'react';
import {
  Alert,
  Card, Collapse, Grid, TextField, Typography,
} from '@mui/material';
import * as XLSX from 'xlsx';
import { LoadingButton } from '@mui/lab';
import OutputValidationResultList from '../../components/OutputValidationResultList';
import FileSelector from '../../components/FileSelector';
import { OutputRow } from '../../entities/output';
import { ValidationResult } from '../../entities/validation';
import { saveOutput, validateOutput } from '../../workers';
import SelectSupplierForm from '../../components/SelectSupplierForm';
import Supplier from '../../entities/supplier';
import FirebaseContext from '../../firebaseContext';

function MgmtSavedOutputs() {
  const { db } = React.useContext(FirebaseContext);

  const [output, setOutput] = React.useState<OutputRow[] | null>(null);
  const [validationResults, setValidationResults] = React.useState<ValidationResult[] | null>(null);
  const [file, setFile] = React.useState<File | undefined>();
  const [supplier, setSupplier] = React.useState<Supplier | undefined>({
    id: '',
    name: '',
    code: '',
    itemCodeTemplate: '',
  });
  const [name, setName] = React.useState('');
  const [loadingSave, setLoadingSave] = React.useState(false);
  const [loadingValidate, setLoadingValidate] = React.useState(false);
  const [showSuccess, setShowSuccess] = React.useState(false);

  React.useEffect(() => {
    if (!file) return;

    setValidationResults(null);
    setShowSuccess(false);

    file.arrayBuffer()
      .then((data) => {
        const workbook = XLSX.read(data, { raw: true });
        const sheet = workbook.Sheets[workbook.SheetNames[0]];
        const json: OutputRow[] = XLSX.utils.sheet_to_json(sheet, { header: 'A' });
        json.splice(0, 1);
        setOutput(json);
      });
  }, [file]);

  React.useEffect(() => {
    if (!output || output.length === 0) return;
    setLoadingValidate(true);

    validateOutput(output)
      .then((data) => setValidationResults(data))
      .catch((error) => console.error(error))
      .finally(() => setLoadingValidate(false));
  }, [output]);

  const handleSave = async () => {
    if (!supplier || !output) return;

    setLoadingSave(true);
    await saveOutput(db, output, supplier, name);
    setLoadingSave(false);
    setShowSuccess(true);
  };

  const validationFailed = !validationResults || validationResults.some((v) => v.result === 'error');

  return (
    <Grid container columnSpacing={3} rowSpacing={3}>
      <Grid item xs={12} md={6}>
        <Card sx={{ p: 2 }}>
          {!validationResults && !loadingValidate ? (
            <Typography sx={{ fontStyle: 'italic' }}>
              Kies eerst een bestand.
            </Typography>
          ) : (
            <>
              <Typography sx={{ fontStyle: 'italic' }}>
                Aantal gelezen regels:
                {' '}
                {output?.length || '???'}
              </Typography>
              <OutputValidationResultList
                validationResults={validationResults}
                loading={loadingValidate}
              />
            </>
          )}
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card sx={{ p: 2 }}>
          <FileSelector setFile={setFile} file={file} />
        </Card>
        <Card sx={{ mt: 3, p: 2 }}>
          <Collapse in={showSuccess} sx={{ marginBottom: '1rem' }}>
            <Alert severity="success">
              Prijslijst successvol opgeslagen!
            </Alert>
          </Collapse>
          <SelectSupplierForm
            enabled
            noCreateNew
            supplier={supplier}
            setSupplier={setSupplier}
          />
          <TextField
            fullWidth
            sx={{ marginTop: '1rem' }}
            margin="dense"
            label="Naam"
            value={name}
            onChange={(event) => setName(event.target.value)}
          />
          <LoadingButton
            sx={{ mt: 1 }}
            variant="contained"
            disabled={validationFailed || !supplier || !name || showSuccess}
            loading={loadingSave}
            onClick={handleSave}
          >
            Opslaan
          </LoadingButton>
        </Card>
      </Grid>
    </Grid>
  );
}

export default MgmtSavedOutputs;
