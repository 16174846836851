import { Auth, getAuth } from 'firebase/auth';
import React from 'react';
import firebase from 'firebase/compat/app';
import Firestore = firebase.firestore.Firestore;

interface IFirebaseContext {
  db: Firestore
  auth: Auth
}

const firebaseConfig = {
  apiKey: 'AIzaSyDqmjl93McOySbRfL_uUWw23o2QJZw6wvw',
  authDomain: 'mkkpricelists.firebaseapp.com',
  projectId: 'mkkpricelists',
  storageBucket: 'mkkpricelists.appspot.com',
  messagingSenderId: '523210493290',
  appId: '1:523210493290:web:de29014d327f08cc6c6913',
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
const auth = getAuth();

const FirebaseContext = React.createContext<IFirebaseContext>({
  db,
  auth,
});

export default FirebaseContext;
