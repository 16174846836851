import React from 'react';
import { FormHelperText } from '@mui/material';
import { ErrorOutline } from '@mui/icons-material';

interface Props {
  duplicates: string[];
}

function DuplicateError({ duplicates }: Props) {
  if (duplicates.length === 0) return null;

  const getMessage = () => {
    const d = duplicates.map((s) => `"${s}"`);
    const last = d.pop();

    if (d.length > 0) {
      return `${d.join(', ')} en ${last}`;
    }
    return last;
  };

  return (
    <FormHelperText sx={{ display: 'flex' }}>
      <ErrorOutline sx={{ marginRight: '0.25rem' }} fontSize="small" />
      De output
      {' '}
      {duplicates.length === 1 ? 'kolom' : 'kolommen'}
      {' '}
      {getMessage()}
      {' '}
      {duplicates.length === 1 ? 'wordt' : 'worden'}
      {' '}
      al ergens anders gebruikt!
    </FormHelperText>
  );
}

export default DuplicateError;
