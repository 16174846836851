import React from 'react';
import { LoadingButton } from '@mui/lab';
import { Alert, Box, Typography } from '@mui/material';
import { OutputRow } from '../../entities/output';
import StepCard from '../StepCard';
import { removeDuplicatesFromOutput } from '../../workers';

interface Props {
  parsedOutputFile: OutputRow[] | null;
  setParsedOutputFile: (output: OutputRow[]) => void;
  validationFailed: boolean;
}

function DeleteDuplicates({ parsedOutputFile, setParsedOutputFile, validationFailed }: Props) {
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(true);
  const [nrOfDuplicatesRemoved, setNrOfDuplicatesRemoved] = React
    .useState<number | null>(null);

  const handleDelete = async () => {
    if (parsedOutputFile == null) return;
    setLoading(true);
    const result = await removeDuplicatesFromOutput(parsedOutputFile);
    setSuccess(true);
    setParsedOutputFile(result.newOutput);
    setLoading(false);
    setNrOfDuplicatesRemoved(result.nrOfRemovedRows);
  };

  React.useEffect(() => {
    if (success) {
      setSuccess(false);
      return;
    }
    setNrOfDuplicatesRemoved(null);
  }, [parsedOutputFile]);

  return (
    <StepCard step={10} title="Verwijder dubbele producten" sx={{ mt: 3 }}>
      <Box>
        Er kunnen dubbele producten in de nieuwe (input) lijst staan. Wanneer twee rijen
        volledig hetzelfde zijn, kun je één van deze twee verwijderen.
      </Box>

      <Box sx={{ mt: 1 }}>
        <Typography sx={{ fontStyle: 'italic' }}>
          Deze stap is optioneel en kun je dus overslaan.
        </Typography>
      </Box>

      <Box sx={{ mt: 2 }}>
        <LoadingButton
          variant="contained"
          color="error"
          onClick={handleDelete}
          loading={loading}
          disabled={parsedOutputFile == null || nrOfDuplicatesRemoved != null || !validationFailed}
        >
          Verwijder dubbele producten
        </LoadingButton>
      </Box>

      {nrOfDuplicatesRemoved != null && (
      <Box sx={{ mt: 2 }}>
        <Alert severity="info">
          Er zijn
          {' '}
          {nrOfDuplicatesRemoved}
          {' '}
          producten verwijderd.
        </Alert>
      </Box>
      )}
    </StepCard>
  );
}

export default DeleteDuplicates;
