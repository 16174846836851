import firebase from 'firebase/compat/app';
import {
  supplierProductCodeColumnKey, codeColumnKey, OutputRow, getMatchingKeys,
} from '../entities/output';
import QueryDocumentSnapshot = firebase.firestore.QueryDocumentSnapshot;
import { powerSet } from '../helpers/array';
import { getDuplicates, twoValuesEqual } from './validateOutput';

/**
 * Split the template string into prefix, the digit string and suffix.
 * @param template
 */
export function parseItemCodeTemplate(template: string): {
  prefix: string;
  digits: string;
  suffix: string;
} {
  const digitsRegEx = template.match('(\\d+)(?!.*\\d)');
  if (digitsRegEx == null) {
    throw new Error(`Item code template \`${template}\` does not contain any digits!`);
  }
  const digits = digitsRegEx[0].toString();
  const digitsPos = template.lastIndexOf(digits);
  const prefix = template.substring(0, digitsPos);
  const suffix = template.substring(digitsPos + digits.length);

  return { prefix, digits, suffix };
}

/**
 * Get the OutputRows from Firestore
 * @param output
 */
export async function getOutputFromStorage(output: QueryDocumentSnapshot): Promise<OutputRow[]> {
  const result: OutputRow[] = [];

  const records = (await output.ref.collection('records').get()).docs;
  records.forEach((record) => {
    result.push(...JSON.parse(record.get('chunk')));
  });

  return result;
}

/**
 * Try to find existing products in the old price list. If it exists, copy the
 * product code to the same product in the new list.
 * @param oldOutput
 * @param rows
 */
export async function matchOutputs(oldOutput: QueryDocumentSnapshot, rows: OutputRow[]) {
  const oldRows = await getOutputFromStorage(oldOutput);

  let matchColumnKeys = getMatchingKeys(supplierProductCodeColumnKey);
  const matchColumnKeySets = powerSet(matchColumnKeys);

  // Get a smaller set of columns that together define a unique identifier for every row.
  // For example, if every supplier item code is unique, we do not need a composite key with
  // the item's barcode
  matchColumnKeys = matchColumnKeySets
    .filter((keys) => keys.length > 0)
    .find((keys) => {
      const newHasNoDuplicates = getDuplicates(keys, rows).length === 0;
      const oldHasNoDuplicates = getDuplicates(keys, oldRows).length === 0;
      return newHasNoDuplicates && oldHasNoDuplicates;
    })
    || matchColumnKeys;

  return rows.map((newRow) => {
    if (newRow[codeColumnKey] != null) return newRow;
    const match = oldRows.find((oldRow) => matchColumnKeys
      // For a row, all the given column must match to match two rows.
      .every((key) => twoValuesEqual(newRow[key], oldRow[key])));
    if (match) {
      // eslint-disable-next-line no-param-reassign
      newRow[codeColumnKey] = match[codeColumnKey];
    }
    return newRow;
  });
}

/**
 * Assign every row a code according to the template if it does not have one yet
 * @param rows
 * @param itemCodeTemplate
 * @param outputs
 */
export default async function assignItemCodes(
  rows: OutputRow[],
  itemCodeTemplate: string,
  outputs?: QueryDocumentSnapshot[],
): Promise<OutputRow[]> {
  const { prefix, digits, suffix } = parseItemCodeTemplate(itemCodeTemplate);
  const digitsLength = digits.length;
  let count = parseInt(digits, 10);

  let result: OutputRow[] = structuredClone(rows);
  if (outputs) {
    for (let i = 0; i < outputs.length; i += 1) {
      // eslint-disable-next-line no-await-in-loop
      result = await matchOutputs(outputs[i], result);
    }
  }

  result.forEach((row) => {
    if (row[codeColumnKey] === undefined) {
      // eslint-disable-next-line no-param-reassign
      row[codeColumnKey] = `${prefix}${count.toString().padStart(digitsLength, '0')}${suffix}`;
      count += 1;
    }
  });

  return result;
}
