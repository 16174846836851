import React from 'react';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import firebase from 'firebase/compat/app';
import { updateDoc } from 'firebase/firestore';
import {
  codeColumnKey, getMatchingKeys, OutputRow, supplierProductCodeColumnKey,
} from '../entities/output';
import Supplier from '../entities/supplier';
import FirebaseContext from '../firebaseContext';
import { getOutputFromStorage } from '../workers/assignItemCodes';
import { addOutputsToExistingList, calculateNewTemplate, rowsMatch } from '../helpers/saveOutput';
import { InputListType } from '../entities/input';
import QueryDocumentSnapshot = firebase.firestore.QueryDocumentSnapshot;

interface Props {
  outputRows: OutputRow[] | null;
  supplier: Supplier;
  validationFailed: boolean;
  selectedOutputList?: QueryDocumentSnapshot;
  inputListType?: InputListType;
  saved: boolean;
  setSaved: (saved: boolean) => void;
}

function SaveOutputExistingModal({
  outputRows, supplier, validationFailed, selectedOutputList, inputListType, saved, setSaved,
}: Props) {
  const { db } = React.useContext(FirebaseContext);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState('');

  React.useEffect(() => {
    setError('');
  }, [outputRows]);

  const intersect = (a: OutputRow[], b: OutputRow[], columns: string[]) => a.filter((row1) => b
    .some((row2) => columns
      .every((c) => row1[c] === row2[c])));

  const handleSaveAsAddendum = async () => {
    if (outputRows == null || outputRows.length === 0 || selectedOutputList === undefined) return;
    const leftOverRows = [...outputRows];

    const existingOutputRows = (await selectedOutputList.ref.collection('records').get()).docs;

    existingOutputRows.forEach((rowDocument) => {
      const parsedRows: OutputRow[] = JSON.parse(rowDocument.get('chunk'));
      const updatedRows = parsedRows.map((row1) => {
        const matchIndex = leftOverRows.findIndex((row2) => rowsMatch(row1, row2, codeColumnKey));
        if (matchIndex >= 0) {
          const match = leftOverRows[matchIndex];
          leftOverRows.splice(matchIndex, 1);
          console.log(leftOverRows);
          return match;
        }
        return row1;
      });

      rowDocument.ref.update({
        chunk: JSON.stringify(updatedRows),
      });
    });

    if (leftOverRows.length > 0) {
      await addOutputsToExistingList(selectedOutputList, leftOverRows);
      // Update itemCodeTemplate for supplier
      const itemCodeTemplate = calculateNewTemplate(outputRows, supplier);
      const supplierRef = await db.collection('suppliers').doc(supplier.id);
      await updateDoc(supplierRef, {
        itemCodeTemplate,
      });
    }

    setSaved(true);
    setOpen(false);
  };

  const handleSaveAsAddition = async () => {
    if (outputRows == null || outputRows.length === 0 || selectedOutputList == null) return;

    const existingOutputRows = await getOutputFromStorage(selectedOutputList);

    // Check whether there are no duplicates in both lists
    const itemCodeDuplicates = intersect(
      existingOutputRows,
      outputRows,
      getMatchingKeys(codeColumnKey),
    );
    const acSupplierDuplicates = intersect(
      existingOutputRows,
      outputRows,
      getMatchingKeys(supplierProductCodeColumnKey),
    );
    if (itemCodeDuplicates.length > 0) {
      setError('Er zijn twee producten met dezelfde code! Je kunt daarom niet deze output samenvoegen met de gekozen lijst!');
      return;
    }
    if (acSupplierDuplicates.length > 0) {
      setError('Er zijn twee producten met dezelfde leveranciercode! Je kunt daarom niet deze output samenvoegen met de gekozen lijst!');
      return;
    }

    await addOutputsToExistingList(selectedOutputList, outputRows);

    // Update itemCodeTemplate for supplier
    const itemCodeTemplate = calculateNewTemplate(outputRows, supplier);
    const supplierRef = await db.collection('suppliers').doc(supplier.id);
    await updateDoc(supplierRef, {
      itemCodeTemplate,
    });

    setSaved(true);
    setOpen(false);
  };

  const handleSave = async () => {
    setLoading(true);

    if (inputListType === InputListType.ADDENDUM) {
      await handleSaveAsAddendum();
    } else {
      await handleSaveAsAddition();
    }

    setLoading(false);
  };

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        disabled={validationFailed || inputListType === undefined || saved}
        variant="contained"
        sx={{ mt: '0.5rem' }}
      >
        Sla output op
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth>
        <DialogTitle>Opslaan bij bestaande lijst</DialogTitle>
        <DialogContent>
          {error !== '' && (
            <Alert severity="error">
              {error}
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <LoadingButton
            variant="contained"
            disabled={validationFailed || selectedOutputList === undefined || error !== ''}
            onClick={handleSave}
            loading={loading}
          >
            Sla output op
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default SaveOutputExistingModal;
