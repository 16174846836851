import React from 'react';
import {
  Box, FormControl, MenuItem, Select, TextField, Typography,
} from '@mui/material';
import {
  AggregateOperation,
  aggregateOperations,
  Merger,
  outputOperationExplanations,
  OutputOperationType, supportedOutputOperations,
} from '../../entities/mappers';
import { columnTypeSet, InputColumnHeader } from '../../entities/input';
import { outputOperationValid } from '../../helpers/validateParams';

interface Props {
  merger: Merger;
  index: number;
  updateMerger: (merger: Merger, index: number) => void;
  inputHeaders: InputColumnHeader[];
}

function MergerOperations({
  merger, index, updateMerger, inputHeaders,
}: Props) {
  const updateAggregateOperation = (aggregateOperation: AggregateOperation) => {
    updateMerger({
      ...merger,
      outputOperation: undefined,
      aggregateOperation,
    }, index);
  };

  const updateOutputOperation = (type: OutputOperationType | '') => {
    if (merger.aggregateOperation === AggregateOperation.APPEND) return;
    // Nothing selected, so remove output operation
    if (type === '') {
      updateMerger({
        ...merger,
        outputOperation: undefined,
      }, index);
      return;
    }

    // Choose default "by" value
    let by: number;
    switch (type) {
      case OutputOperationType.MULTIPLY:
        by = 1;
        break;
      case OutputOperationType.ADD:
      default:
        by = 0;
        break;
    }

    updateMerger({
      ...merger,
      outputOperation: {
        type: type as OutputOperationType,
        by,
        byRaw: by.toString(),
      },
    }, index);
  };

  const changeOutputOperationBy = (byRaw: string) => {
    if (merger.outputOperation === undefined) return;
    updateMerger({
      ...merger,
      outputOperation: {
        ...merger.outputOperation,
        byRaw,
        by: parseFloat(byRaw),
      },
    }, index);
  };

  const inputs = merger.inputIds
    .map((id) => inputHeaders.find((h) => h.id === id)!)
    .filter((i) => i !== undefined);
  const sharedInputsColumnType = columnTypeSet(inputs.map((i) => i.type));
  const outputOperations = supportedOutputOperations[merger.aggregateOperation];

  return (
    <Box>
      <Box sx={{ marginTop: '1.5rem' }}>
        <Typography variant="h5">Manier van samenvoegen</Typography>
        <FormControl fullWidth>
          <Select
            size="small"
            value={merger.aggregateOperation}
            onChange={(event) => updateAggregateOperation(event.target.value as AggregateOperation)}
          >
            {(Object.keys(aggregateOperations) as AggregateOperation[]).map((op) => (
              <MenuItem
                value={op}
                key={op}
                disabled={!aggregateOperations[op].columnTypes.includes(sharedInputsColumnType)}
              >
                {aggregateOperations[op].explanation}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      {outputOperations.length > 0 && (
        <Box sx={{ marginTop: '1.5rem' }}>
          <Typography variant="h6">Extra bewerkingen na samenvoegen</Typography>
          <Typography variant="body2" sx={{ fontStyle: 'italic' }}>
            Deze optie is optioneel en mag leeg blijven.
          </Typography>
          <FormControl fullWidth>
            <Select
              placeholder="Niets doen"
              size="small"
              value={merger.outputOperation?.type ?? ''}
              onChange={(event) => updateOutputOperation(event.target.value as OutputOperationType)}
            >
              <MenuItem value="">
                <span style={{ fontStyle: 'italic' }}>Niets doen</span>
              </MenuItem>
              {outputOperations.map((op) => (
                <MenuItem key={op} value={op}>
                  {outputOperationExplanations[op]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {merger.outputOperation !== undefined && (
            <TextField
              placeholder="Getal"
              margin="dense"
              value={merger.outputOperation.byRaw}
              onChange={(event) => changeOutputOperationBy(event.target.value)}
              size="small"
              fullWidth
              error={!outputOperationValid(merger)}
            />
          )}
        </Box>
      )}
    </Box>
  );
}

export default MergerOperations;
