import React from 'react';
import {
  Alert,
} from '@mui/material';
import StepCard from '../StepCard';
import Supplier from '../../entities/supplier';
import SelectSupplierForm from '../SelectSupplierForm';

interface Props {
  supplier: Supplier | undefined
  setSupplier: (supplier: Supplier) => void
  enabled: boolean;
}

function SelectSupplier({ supplier, setSupplier, enabled }: Props): JSX.Element {
  return (
    <StepCard step={1} title="Kies leverancier">
      {!enabled && (
        <Alert severity="info" sx={{ marginBottom: '2rem' }}>
          Reset het formulier als je de leverancier wilt veranderen.
          LET OP: dit verwijdert al je werk als je dit niet hebt opgeslagen/geëxporteerd!
        </Alert>
      )}
      <SelectSupplierForm enabled={enabled} supplier={supplier} setSupplier={setSupplier} />
    </StepCard>
  );
}

export default SelectSupplier;
